import React, {useContext, useEffect, useState} from 'react';
import {RiddleContext} from "../riddle/screen-riddle";
import {useDispatch, useSelector} from "react-redux";
import {tools} from "./tools";
import classNames from "classnames";
import ToolClues from './clues/tool-clues';
import {ToolTopBar} from "./tool-top-bar/tool-top-bar";
import {ScreenToolIcon} from "./screen-tool-icon";
import {ToolPassword} from "./password/tool-password";
import {Button, Image, Paragraph} from "../../01_atoms";
import * as journeyActions from "../../../store/actions/journey/journey-actions";
import "./screen-tool.scss";

export const FrameContext = React.createContext({handleFrameFinish: null, isFrameFinish: null});

export const ScreenTool = (
    {
        toolId,
        toolData,
        handleFrameContinue,
        isLastFrame,
        riddles,
        riddleIndex,
        riddleData,
        previewMode,
        isFullScreen
    }) => {
    const riddleContext = useContext(RiddleContext);

    const [clueActive, setClueActive] = useState(false);
    const [actionsButtonsActive, setActionsButtonsActive] = useState(false);
    const [finalButtonChosen, setFinalButtonChosen] = useState(null);

    const journey = useSelector(state => state.journey);

    const {id, categorydata, clues, thumbnail, style} = toolData;
    const frameData = categorydata;
    const {textBefore, textBeforeColor, textBeforeSize, textAfter, textAfterColor, textAfterSize, imageBefore, imageAfter, imageBeforeSize, imageAfterSize, frameAudio, audioPlayOn, actionButtons, actionButtonSize, actionButtonBackgroundColor, actionButtonColor} = frameData;

    const isFrameFinish = journey[`frame_${id}_finish`];

    useEffect(() => {
        if (finalButtonChosen) {
            handleFrameFinish();
        }
    }, [finalButtonChosen]);

    useEffect(() => {

        if (frameAudio && audioPlayOn === "background") {
            playFrameAudio();
        }
    }, [frameAudio, audioPlayOn, id]);

    const playFrameAudio = () => {
        const audioPlayer = document.getElementById(`frame-audio-${id}`);
        if (audioPlayer) {
            document.getElementById(`frame-audio-${id}`).play()
        }

    }

    const closeClue = callback => {
        setClueActive(false);
        setTimeout(callback, 1000);
    };

    const dispatch = useDispatch();

    const handleFrameFinish = () => {
        if (frameAudio) {
            playFrameAudio();
        }

        dispatch(journeyActions.frameFinish(id));
    }

    const nextRiddle = riddles[riddleIndex + 1];
    const goTo = (nextRiddle && toolData["after-riddle-target"] === "מעבר לחידה הבאה") ? `/riddle/${nextRiddle.id}` : "/menu";

    const frameContext = {handleFrameFinish, isFrameFinish};

    const ToolComponent = tools.find(tool => tool.id === toolId).component;

    // const toolBackgroundImage = frameData.screenBackgroundImage;
    // const toolBackgroundColor = frameData.backgroundColor;
    // const password = frameData.password;
    // const passwordLabel = frameData.passwordLabel;
    const {screenBackgroundImage, backgroundColor, innerPassword, innerPasswordLabel, continueButtonValue = "המשך"} = frameData

    const nextFrameButton = <Button className="riddle-next-button" onClick={handleFrameContinue}
                                    value={continueButtonValue}/>;

    const showActionButtons = actionButtons && actionButtons.length > 0;
    const lastFrame = (
        toolData?.id === 2034 ||
        toolData?.id === 3836 ||
        toolData?.id === 4112 ||
        toolData?.id === 3886 ||
        toolData?.id === 3861 ||
        toolData?.id === 4200);
    return (
        <FrameContext.Provider value={frameContext}>
            <div id='screen-tool'
                 className={classNames(`screen-tool screen-tool-${toolId} frame-${id}`,
                     {fullScreen: isFullScreen, ["tool-coder"]: toolId === 63},
                     {hasTextBefore: textBefore})}>
                {/*<div className={previewMode ? "preview" : ""} style={{minHeight: clueActive ? "100vh" : "auto"}}>*/}



                {!!clues && clueActive &&
                <ToolClues clueIndex={0}
                           cluesData={clues}
                           close={closeClue}
                           isActive={clueActive}/>}


                {ToolComponent &&
                <div className={classNames("tool-wrapper", {fullScreen: isFullScreen})}
                     style={{
                         backgroundImage: screenBackgroundImage ? `url(${screenBackgroundImage})` : "",
                         backgroundColor: backgroundColor,
                         ...style && style
                     }}>

                    {riddles[riddleIndex].id === 12614 &&
                    <button style={{fontWeight:"bold", outline:0, fontSize:"16px", border: "2px solid #b99370", display:"block", color:"#b99370", backgroundColor:"#fff", borderRadius:"4px", padding: "4px 13px", margin: "10px auto 20px"}}>
                        המפקח רמז
                    </button>
                    }
                    {!isFullScreen && toolData.thumbnail && <ScreenToolIcon icon={thumbnail} width={80}/>}

                    {textBefore && <p dangerouslySetInnerHTML={{__html:textBefore}}
                                              style={{color:textBeforeColor, fontSize:`${textBeforeSize || "18"}px`}}
                                              className="tool-text-before"/>}


                    {imageBefore && <Image src={imageBefore} width={imageBeforeSize || "100%"}/>}

                    <ToolComponent {...frameData}
                                   toolData={toolData}
                                   handleFrameFinish={handleFrameFinish}
                                   handleRiddleFinish={riddleContext.handleRiddleFinish}
                                   handleFrameContinue={handleFrameContinue}
                                   riddleId={riddleData.id}
                                   nextFrameButton={isFrameFinish && !isFullScreen && !isLastFrame && nextFrameButton}
                                   showActionsButton={() => setActionsButtonsActive(true)}
                                   goTo={goTo}/>

                    {textAfter && <Paragraph value={textAfter}
                                             color={textAfterColor}
                                             fontSize={`${textAfterSize || "18"}px`}
                                             className="tool-text-after"/>}

                    {imageAfter &&
                    <Image src={imageAfter} width={imageAfterSize || "100%"} style={{marginTop: "30px"}}/>}

                    {showActionButtons && !actionsButtonsActive && !isFrameFinish &&
                    <div className="screen-tool-buttons-trigger">
                        <Button onClick={() => setActionsButtonsActive(true)}>המשך</Button>
                    </div>
                    }

                    {((showActionButtons && actionsButtonsActive) || (actionButtons && isFrameFinish)) &&
                    <div className="screen-tool-buttons">
                        {actionButtons.map((button, index) => {
                            const isActive = finalButtonChosen === index + 1;
                            return <Button key={index}
                                           value={button}
                                           onClick={() => setFinalButtonChosen(index + 1)}
                                           style={{
                                               borderRadius: "20px",
                                               textDecoration: isActive ? "underline" : "none",
                                               border: `4px solid ${isActive ? "#000" : "transparent"}`,
                                               backgroundColor: actionButtonBackgroundColor,
                                               color: actionButtonColor,
                                               fontSize: actionButtonSize,
                                           }}/>
                        })}
                    </div>}

                    {frameAudio && <audio style={{height:"100px", visibility:isFrameFinish ? "visible":"hidden"}} id={`frame-audio-${id}`} controls>
                        <source src={frameAudio} type="audio/ogg"/>
                        <source src={frameAudio} type="audio/mpeg"/>
                    </audio>}

                    {!!innerPassword?.trim() && <ToolPassword label={innerPasswordLabel}
                                                              password={innerPassword}
                                                              successHandler={handleFrameFinish}/>}


                    {toolData?.id !== 3885 && (!actionButtons || finalButtonChosen) && isFrameFinish && !isFullScreen && !isLastFrame && nextFrameButton}

                </div>
                }
                {/*</div>*/}


            </div>

            {!isFullScreen &&
            <ToolTopBar riddleData={riddleData}
                        tool={toolData}
                        hideBackButton={lastFrame || toolData.id === 4122}
                        setClueActive={setClueActive}
                        hasClues={clues?.length > 0}
                        previewMode={previewMode}/>}

        </FrameContext.Provider>
    )
};
