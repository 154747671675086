class Word {
    constructor(hebrew, english, arabic) {
        this.Hebrew = hebrew;
        this.English = english;
        this.Arabic = arabic;
    }
}

export const DICTIONARY = {
    password_title:      new Word("יש להכניס סיסמה", "Insert Password", "يجب إدخال كلمة مرور"),
    wrong_password:      new Word("סיסמה שגוייה", "Wrong password", "كلمة سر خاطئة"),
    welcome:             new Word("ברוכים הבאים", "Welcome", "اهلا وسهلا"),
    are_you_ready:       new Word("התחילו בחקירה", "Are You Ready?", "جاهزون؟"),
    clue:                new Word("רמז", "clue", "دليل"),
    solution:            new Word("פיתרון", "solution", "المحلول"),
    question:            new Word("שאלה", "question", "سؤال"),
    continue:            new Word("המשך", "continue", "اكمل"),
    you_answered:        new Word("עניתם נכון על", "You answered", "لقد أجبت على"),
    out_of:              new Word("מתוך", "out of", "من أصل"),
    questions_correctly: new Word("שאלות", "questions correctly", "أسئلة بشكل صحيح"),
    go_back:             new Word("חזור", "go back", "إرجاع"),
    well_done:           new Word("כל הכבוד!", "Well done!", "كل الاحترام!"),
    winning_selfie:      new Word("צלמו והעלו תמונת ניצחון", "For Winning selfies", "لالتقاط صور شخصية للنصر"),
    name:                new Word("שם", "Name", "اسم"),
    first_name:          new Word("שם פרטי", "First name", "اسم شخصي"),
    how_was_it:          new Word("איך היה?", "How was it?", "كيف كان؟"),
    tell_us:             new Word("ספרו לנו איך היה?", "Tell us how was it?", "أخبرنا كيف كان الأمر"),
    share_experience:    new Word("שתפו בחווייה מהמשחק", "Share the experience", "شارك التجربة"),
    join_our_community:  new Word("הצטרפו לקהילה שלנו", "Join our community and receive a gift", "انضم إلى مجتمعنا واحصل على هدية"),
    email_address:       new Word("כתובת דואר אלקטרוני", "E-mail address", "عنوان البريد الالكتروني"),
    share:               new Word("שתף", "Share", "إرسال"),
    the_answer_is_the_ode_To_The_next_question:               new Word("התשובה היא הקוד שיפתח את הכלי הדיגיטלי הבא", "The answer is the code that will open the next digital tool", "الجواب هو الكود الذي سيفتح اللغز التالي!"),
    upload_a_victory_photo:               new Word("העלו תמונת ניצחון ושתפו בחוויתכם מההרפתקה!", "Upload a victory photo and share your experience from the adventure!", "قم بتحميل صورة انتصار وشارك تجربتك من المغامرة!"),
    send:                new Word("שלח", "Send", "إرسال")


};