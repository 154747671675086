import React, {useState, useEffect} from 'react';
import {Col} from 'react-grid-system';
import INSTA from '../../../assets/images/digital-tools-icons/insta.png';
import WHATSAPP from '../../../assets/images/digital-tools-icons/whatsappnew.png';
import SHAZAM from '../../../assets/images/digital-tools-icons/shazam.png';
import SETTINGS from '../../../assets/images/digital-tools-icons/settings.png';
import GOOGLEPLAY from '../../../assets/images/digital-tools-icons/google_play.png';
import CALCULATOR from '../../../assets/images/digital-tools-icons/calculator_1.png';
import WAYZ from '../../../assets/images/digital-tools-icons/wayz.png';
import ASANA from '../../../assets/images/digital-tools-icons/asana.png';
import MESSENGER from '../../../assets/images/digital-tools-icons/messengernew.png';
import {Image, Button} from '../../01_atoms';
import {iconLink, iconWrapper, RiddleIconLabel} from "./menu-style";


const getIconByName = name => {
    switch (name) {
        case 'insta':
            return INSTA;
        case 'whatsapp':
            return WHATSAPP;
        case 'shazam':
            return SHAZAM;
        case 'settings':
            return SETTINGS;
        case 'google':
            return GOOGLEPLAY;
        case 'calculator':
            return CALCULATOR;
        case 'waze':
            return WAYZ;
        case 'asana':
            return ASANA;
        case 'messenger':
            return MESSENGER;
        default:
            return SETTINGS;
    }
};

const ToolIcon = ({name, colSize}) => {
    const [shakeActive, setShakeActive] = useState(false);
    const icon = getIconByName(name);

    useEffect(() => {
        if (shakeActive) {
            setTimeout(() => {
                setShakeActive(false);
            }, 1000)
        }
    }, [shakeActive]);

    return (
        <Col xs={colSize} style={iconWrapper}>
            <Button className={shakeActive ? 'shake' : ''} style={iconLink} onClick={() => setShakeActive(true)}>
                <Image src={icon} width={'100px'}/>
            </Button>
            <RiddleIconLabel>{name}</RiddleIconLabel>

        </Col>)
};



export default ToolIcon;