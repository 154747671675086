import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {MdPlayCircleFilled, MdPlayCircleOutline} from "react-icons/md";
import {Image} from "../../01_atoms";

export const AudioListItem = (
    {
        name,
        title,
        image,
        backgroundImage,
        color,
        index,
        audio,
        isActive,
        imageSize,
        toggleAudio,
        textColor,
        itemStyle,
        list_view,
    }) => {
    const {borderWidth, borderColor} = itemStyle;

    const id = `tool-audio-player-${index}`;

    useEffect(() => {
        const audioItem = document.getElementById(id);
        if (isActive) {
            audioItem.currentTime = 0;
            audioItem.play();
            audioItem.onended = toggleAudio;
        } else {
            audioItem.pause();
        }
    }, [isActive]);

    const MdIcon = isActive ? MdPlayCircleFilled : MdPlayCircleOutline;

    return (
        <AudioListItemWrapper borderWidth={borderWidth}
                              borderColor={borderColor}
                              backgroundColor={color}
                              backgroundImage={backgroundImage}
                              onClick={toggleAudio}
                              className="audio-list-item">

            <audio controls id={id} className="tool-audio-player">
                <source src={audio} type="audio/ogg"/>
                <source src={audio} type="audio/mpeg"/>
            </audio>


            <div className="audio-list-item-container">
                <div className="audio-list-item-link">
                    <div className="audio-list-item-details">
                        <div>
                            {title && <Name className="audio-list-item-title" color={textColor}>{title}</Name>}
                            <Name className="audio-list-item-name" color={textColor}>{name}</Name>
                        </div>
                    </div>
                </div>

                <div className="audio-list-icons">
                    {list_view === "blocks" &&
                    <div className="audio-list-play-icon">
                        <MdIcon size={25} color={"#1e1d3d"}/>
                    </div>}
                    {image && <Image src={image} width={imageSize || "50px"} className="audio-list-image"/>}
                </div>
            </div>

        </AudioListItemWrapper>
    );
};

const AudioListItemWrapper = styled.div`
    ${props => `
        .audio-list-item-container {
            background-color: ${props.backgroundColor};
            background-image: url(${props.backgroundImage});
        }
        border-bottom: ${props.borderWidth}px solid ${props.borderColor};
    `}
    
`;

const Thumbnail = styled.div`
    ${props => `
        background: ${props.background || 'transparent'};
    `} 
`;
const Name = styled.span`
     ${props => `
         color: ${props.color};
    `};
`;

AudioListItem.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    placeholder: PropTypes.string,
    phone_number: PropTypes.string,
    index: PropTypes.number,
    itemStyle: PropTypes.object,
}