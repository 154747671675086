import React, {useState, useEffect, useReducer, useContext} from 'react';
import {Element, HBox} from 'react-stylesheet';
import {Link} from "react-router-dom";
import {Button, Circle, Heading, Paragraph, Image, BackgroundImage} from '../../01_atoms';
import {colors, gutters, font_size, font_family} from '../../00_base/variables';
import {Container, Row, Col} from 'react-grid-system';
import {Wrapper, Sentence, IndicatorWrapper, OptionSquare} from './truth-or-lie-styles';
import {ScreenToolIcon} from "../../05_screens/tool/screen-tool-icon";
import {RiddleContext} from "../../05_screens/riddle/screen-riddle";
import "./tool-truth-or-lie-style.scss";

const TruthOrLieContext = React.createContext();


const ToolTruthOrLie = (
    {
        items,
        adventureDataId,
        screenBackground,
        screenBackgroundImage,
        borderColor,
        borderWidth,
        questionBackground,
        questionTextColor,
        truthButtonBackground,
        truthButtonTextColor,
        lieButtonBackground,
        lieButtonTextColor,
        continueButtonBackground,
        continueButtonTextColor,
        backButtonBackground,
        backButtonTextColor,
        bottomLogo,
        logoWidth,
        thumbnail,
        nextFrameButton
    }) => {
    const riddleContext = useContext(RiddleContext);
    const {savePlayerData, playerData} = riddleContext;

    const answersReducer = (state, action) => {
        const index = action.index;
        state[index].u = action.value;
        savePlayerData(state);
        return state;
    };

    const [activeSentence, setActiveSentence] = useState(0);
    const [showIncorrectAnswers, setShowIncorrectAnswers] = useState(false);

    const defaultAnswers = items.map(item => {
        return {a: item.sentence_type === "אמת" ? 0 : 1, u: -1}
    });

    const [answers, dispatchAnswers] = useReducer(answersReducer, JSON.parse(playerData) || defaultAnswers);
const finalScreenActive =  activeSentence >= items.length;

    return (
        <TruthOrLieContext.Provider value={{
            adventureDataId,
            answers,
            dispatchAnswers,
            items,
            activeSentence,
            setActiveSentence,
            showIncorrectAnswers,
            setShowIncorrectAnswers,
            screenBackground,
            borderColor,
            borderWidth,
            questionBackground,
            questionTextColor,
            truthButtonBackground,
            truthButtonTextColor,
            lieButtonBackground,
            lieButtonTextColor,
            continueButtonBackground,
            continueButtonTextColor,
            backButtonBackground,
            backButtonTextColor,
            bottomLogo,
            logoWidth
        }}>

            <Wrapper className="tool tool-truth-or-lie"
                     screenBackground={screenBackground}
                     // screenBackgroundImage={screenBackgroundImage}
                     borderColor={borderColor}
                     borderWidth={borderWidth}>
                <Container className="tool-truth-or-lie-container">
                    {
                        finalScreenActive ?
                            <TruthOrLieFinalScreen adventureDataId={adventureDataId}/>
                            :
                            <>
                                {thumbnail && <ScreenToolIcon icon={thumbnail}/>}

                                {items.map((item, index) => {
                                    const isActive = index === activeSentence;

                                    return (
                                        <TruthOrLieSentence key={item.id}
                                                            isActive={isActive}
                                                            {...item}
                                                            index={index}
                                                            iconTruth={item.icon_truth}
                                                            iconLie={item.icon_lie}/>
                                    )
                                })}

                                <TruthOrLieIndicators size={items.length > 8 ? 20 : 30}/>

                                <HBox justifyContent={'center'} padding={`0 ${gutters.md}`} marginTop={gutters.lg}>

                                    <Button block
                                            value={'המשך'}
                                            onClick={() => setActiveSentence(activeSentence + 1)}
                                            pointer={'pointer'}
                                            style={{
                                                fontFamily: font_family.medium,
                                                backgroundColor: continueButtonBackground,
                                                color: continueButtonTextColor,
                                                borderRadius: "20px",
                                                fontSize: font_size.lg
                                            }}/>
                                </HBox>
                            </>
                    }
                    {/*{finalScreenActive && nextFrameButton && nextFrameButton}*/}
                </Container>
            </Wrapper>
        </TruthOrLieContext.Provider>
    )
};

const TruthOrLieIndicators = ({size, showAnswers}) => {
    const {items, answers, activeSentence, setActiveSentence, finalScreenActive, markIndicators, showIncorrectAnswers} = useContext(TruthOrLieContext);
    return <HBox justifyContent="center" flexWrap={showAnswers ? "wrap" : "nowrap"} marginBottom={showAnswers ? "20px" : 0}>
        {answers.map((answer, index) => {
            const isActive = activeSentence === index;
            const value = answer.u >= 0 ? answer.u + 1 : '';
            const background = items[index].color;
            const checkIcon = markIndicators && answer.u === answer.a;
            const borderColor = isActive ? "black" : (finalScreenActive || markIndicators) ? answer.u === answer.a ? background : "red" : colors[isActive ? 'black' : 'white'];
            const borderWidth = isActive ? 2 : checkIcon ? 0 : finalScreenActive ? 3 : 2;
            const hue = colors.lightOrDark(background);
            const color = hue === 'light' ? colors.black : colors.white;
            const isCorrect = answer.u === answer.a;
            const circleStyle = {
                cursor: "pointer",
                borderRadius: "50%",
                // marginBottom: gutters.sm,
                // marginTop: gutters.sm,
                border: (showAnswers || showIncorrectAnswers) ? isCorrect ? "4px solid green" : "4px solid red" : `2px solid ${borderColor}`
            };

            return <IndicatorWrapper isActive={isActive} padding={items.length ? gutters.xs : gutters.sm} key={index}>
                <Circle value={value}
                        size={size || 20}
                        background={answer.u >= 0 ? "#fff" : background}
                        color={color}
                        fontSize={font_size.sm}
                        fontWeight='bold'
                        border={`${borderWidth}px solid ${borderColor}`}
                        onClick={() => setActiveSentence(index)}
                        style={circleStyle}>
                    {answer.u >= 0 &&
                    <BackgroundImage
                        size="contain"
                        width={"100%"}
                        height={"100%"}
                        url={items[index][answer.u === 0 ? "icon_truth" : "icon_lie"]}/>
                    }
                </Circle>
            </IndicatorWrapper>
        })}
    </HBox>
};

const TruthOrLieSentence = ({id, index, iconLie, iconTruth, sentence_type, sentence, color, isActive}) => {
    const {
        questionBackground = "#fff",
        questionTextColor
    } = useContext(TruthOrLieContext);

    const options = [
        {label: 'נכון', icon: iconTruth},
        {label: 'לא נכון', icon: iconLie}
    ];
    return (
        <Sentence isActive={isActive}>
            <Element  margin={"0 10px"} padding={"10px 30px"}>
                <Paragraph textAlign={'center'}
                           fontWeight={'bold'}
                           color={questionTextColor}
                           fontSize={font_size.sm}
                           value={sentence}/>
            </Element>

            <Options sentenceId={id}
                     index={index}
                     color={"#f2f2f2"}
                     sentence_type={sentence_type}
                     options={options}/>


        </Sentence>
    )
};

const Options = ({index, color, options}) => {
    const {
        dispatchAnswers,
        answers,
        truthButtonBackground,
        truthButtonTextColor,
        lieButtonBackground,
        lieButtonTextColor
    } = useContext(TruthOrLieContext);
    const userAnswer = answers[index].u;
    const [active, setActive] = useState(userAnswer > -1 ? userAnswer : null);

    const clickHandler = answerIndex => {
        setActive(answerIndex);
        dispatchAnswers({index: index, value: answerIndex});
    };

    return (
        <Row style={{alignItems: "center", height:"110px"}}>
            {options.map((option, index) => {
                const isActive = active === index;
                const background = option.label === "אמת" ? truthButtonBackground : lieButtonBackground;
                const color = option.label === "אמת" ? truthButtonTextColor : lieButtonTextColor;
                return (
                    <Col xs={6} style={{ height:"100%",  padding: `${gutters.md} ${gutters.md}`}} key={index}>
                        <OptionSquare onClick={() => clickHandler(index)}
                                      isActive={isActive}
                                      color={color}
                                      background={isActive ? colors.white : background}>
                            {option.icon && (isActive) ? <Image src={option.icon}/> : option.label}
                        </OptionSquare>
                    </Col>
                )
            })}
        </Row>
    )
};

const TruthOrLieFinalScreen = () => {
    const {answers, adventureDataId, items, setActiveSentence, setShowIncorrectAnswers} = useContext(TruthOrLieContext);
    const correctAnswers = answers.filter(item => item.a === item.u);
    const successLength = correctAnswers.length;
    const levelFinished = useContext(RiddleContext).updateGameLevel;
    const firstWrongIndex = answers.indexOf(answers.find(answer => answer.a !== answer.u));

    const allCorrect = successLength === items.length;

    useEffect(() => {
        if (allCorrect) {
            levelFinished();
        }
    }, []);

    return (
        <Element paddingBottom={"20px"}
                 style={{
                     backgroundColor: "rgba(255,255,255,.8)",
                     borderRadius: "20px",
                     textAlign: "center",
                     paddingTop: "20px"
                 }}>
            <Heading tag={"h2"} value={allCorrect ? "כל הכבוד" : `עניתם נכון על ${successLength} שאלות`}/>
            <TruthOrLieIndicators size={90} showAnswers={true}/>

            <Button block
                    style={{fontFamily: font_family.medium}}
                    fontWeight={"bold"}
                    onClick={() => {
                        setShowIncorrectAnswers(true);
                        setActiveSentence(firstWrongIndex || 0)
                    }}>
                {allCorrect ?
                    <Link to={`/menu/${adventureDataId || ''}`} style={{color: "#fff"}}>המשך</Link> : "נסו שוב"}
            </Button>
        </Element>
    )
};

export default ToolTruthOrLie;