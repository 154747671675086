import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import {colors} from "../../../00_base/variables";
import Timer from "../../../03_organisms/timer/timer";
import {Heading, Image, Span} from "../../../01_atoms";
import {MdKeyboardArrowRight} from 'react-icons/md';
import {arrowBackWrapper, clueButton, container, timerWrapper, toolTitle, wrapper} from "./tool-top-bar-style";
import LOGO from "../../../../assets/images/police-logo.png";

export const ToolTopBar = (
    {
        riddleData,
        tool,
        setClueActive,
        previewMode,
        hasClues,
        hideBackButton
    }) => {
    const gameData = useSelector(state => state.gameData);
    const {adventure, adventureType} = gameData;

    const language = adventure.language;
    const adventureName = adventure.json_data && adventure.json_data.adventure_name;

    const riddleTitle = riddleData.title || tool.title;

    return (
        <div id='tool-top-bar' style={{...wrapper, position: previewMode ? 'absolute' : 'fixed'}}>

            <div style={{...arrowBackWrapper, visibility: hideBackButton ? "hidden":"visible"}}>
                <Link to={`/menu`} style={{display:"flex", alignItems:"center"}}>
                    <MdKeyboardArrowRight size={30} color={colors.white}/>
                    <Span value="חזור" color={colors.white} style={{fontSize:"20px",paddingLeft: "15px"}}/>
                </Link>
            </div>

            <Heading tag={'h1'}
                     value={riddleTitle.replace("{name}", adventureName)}
                     style={toolTitle} />

            <div style={container}>
                    <Image src={adventureType?.logo_url || LOGO} height={30} width={30}/>
             </div>

        </div>
    )
};
