import React from 'react';
import Timer from '../../03_organisms/timer/timer';
import {MdWbSunny} from 'react-icons/md';
import {gutters, colors} from '../../00_base/variables';
import {Button, Image} from "../../01_atoms";
import LIGHT from "../../../assets/images/light.png";

const MenuHeader = ({logo, clueMenuActive, setClueMenuActive}) => {
    return (
        <div style={wrapper} className='menu-header'>
            <div style={container}>
                <div style={buttonWrapper}>
                    <Timer color={'#fff'}/>
                </div>
                <Image src={logo} height={"35px"}/>
            </div>
        </div>
    )
};

const wrapper = {
    padding: `${gutters.sm} ${gutters.sm}`,
    background: "#1e1d3d",
    // height: "50px",
    position:"sticky"
};
const container = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '60rem',
    margin: 'auto'
};
const buttonWrapper = {
    display: 'flex',
    alignItems: 'center'
};
const clueButton = {
    // marginLeft: gutters.sm,
    // marginRight: gutters.sm,
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 0,
    backgroundColor: "transparent",
    radius: '10px',
    cursor: 'pointer'
};
const sunnyIcon = {
    fontSize: '20px',
    color: '#fff'
};

export default MenuHeader;