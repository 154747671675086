import React from "react";
import {colors} from "../../00_base/variables";
import {Button} from "../../01_atoms";
import {Link} from "react-router-dom";
import SkewElement from "../skew-element";


export const BackToMenu = ({background = colors.gray_2, value = "חזור", goto = "/menu"}) => {
    return (
        <SkewElement after={{deg: '0', origin: 'left', direction: 'Y'}}
                     before={{deg: '0', origin: 'right', direction: 'Y'}}
                     width="200px"
                    style={{margin:"20px auto"}}
                     background={background}>
            <Button style={{backgroundColor: background, paddingLeft:0, paddingRight:0, fontWeight: 700}} pointer block size={'md'} textAlign='center'>
                <Link to={goto} style={{color:"#000", width:"100%", height: '100%', display:"block"}}>{value}</Link>
            </Button>
        </SkewElement>
    )
};
