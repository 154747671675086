import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from "react-redux";
import {Container, Row, Col} from 'react-grid-system';
import THUMBNAIL from '../../../assets/images/default_thumbnail.jpg';
import GAME_ICON from '../../../assets/images/logos/youdo_logo.png';
import YOUDO from '../../../assets/images/logos/youdo_logo.png';
import {Button, Heading, Image, Paragraph} from '../../01_atoms';
import ScreenLoading from '../loading/screen-loading';
import MenuHeader from './menu-header';
import ToolClues from "../tool/clues/tool-clues";
import "./screen-menu.scss";

import {
    Cover,
    PageWrapper,
    PageContainer,
    IconImage,
    wrapper,
    iconLink,
    iconsContainer,
    iconsWrapper,
    iconWrapper, RiddleIconLabel, IconsWrapper
} from "./menu-style";
import * as journeyActions from "../../../store/actions/journey/journey-actions";
import ToolIcon from "./tool-icon";


const ScreenMenu = props => {
    const english = props.lang === 'en';
    const gameData = useSelector(state => state.gameData);
    // const journey = useSelector(state => state.journey);

    const {adventure, adventureType, adventureData, riddles} = gameData;
    const manInSuite = adventure.id === 4498;


    const logo = adventureType?.logo_url || GAME_ICON;
    const [clueMenuActive, setClueMenuActive] = useState(false);

    const dispatch = useDispatch();

    const activeIcons = riddles?.filter(riddle =>  !!riddle.thumbnail); // !!riddle.templatedata &&
    const showFakeIcons = adventure?.json_data?.show_fake_icons;
    const fakeIcons = adventure?.json_data?.lobby_fake_icons;
    const iconsLength = activeIcons.length + (showFakeIcons ? fakeIcons?.length : 0);
    const colSize = iconsLength <= 6 ? 6 : iconsLength <= 12 ? 4 : 3;

    const goToRiddle = (riddleId) => {
        const pathname = english ? `/riddle-en/${riddleId}/1` : `/riddle/${riddleId}/1`;

        dispatch(journeyActions.riddleStart(riddleId));
        props.history.push(pathname);
    };

    const renderToolIcon = (riddle, index, imgStyle = {}) => {
        const {id, thumbnail, riddle_name} = riddle;
        const specificWrapperStyle = manInSuite ? {
            transform: "scale(1.2)"
        } : {};
        const specificButtonStyle = manInSuite ? {
            padding: 0
        } : {};
        return (
            <Col xs={colSize} key={index} className={"lobby-icon-wrapper"} style={{...iconWrapper, ...specificWrapperStyle}}>
                <Button style={{...iconLink, ...specificButtonStyle}} onClick={() => goToRiddle(id)}> {/* index > gameLevel ? '/menu' : */}
                    <IconImage src={thumbnail || THUMBNAIL} style={imgStyle}/>
                </Button>
                {riddle_name && <RiddleIconLabel fontSize={showFakeIcons ? "14px" : "18px"} className={"riddle-name"}>{riddle_name}</RiddleIconLabel>}
            </Col>
        )
    };

    if (!riddles) return <ScreenLoading/>;

    if (!riddles) {
        return (
            <PageWrapper>
                <Cover>
                    <Image src={YOUDO} width={200}/>
                </Cover>
            </PageWrapper>
        );
    }

    const backgroundMobile = adventure?.json_data?.lobby_image_mobile ||adventureType?.lobby_image_mobile;
    const backgroundTablet = adventure?.json_data?.lobby_image_desktop ||adventureType?.lobby_image_desktop;
    const backgroundDesktop = adventure?.json_data?.lobby_image_desktop ||adventureType?.lobby_image_desktop;
    const lobbyTitle = adventure?.json_data?.lobby_title;
    const lobbySubtitle = adventure?.json_data?.lobby_subtitle;

    const specificIconsWrapper =  manInSuite ? {
        paddingTop:"30px "
    } : {};
    return (
        <div style={wrapper} id='screen-menu' className={`adventure-${adventure?.id}`}>



            <ToolClues isActive={clueMenuActive}
                       close={() => setClueMenuActive(false)}
                       cluesData={[{subtitle: "עקבו אחר רצף החידות (גם במסך וגם בגלויה). שימו לב - פתרון של כל חידה יפתח לכם את הכלי הדיגיטלי הבא\n"}]}/>

            <PageWrapper {...props}
                         mobile={backgroundMobile}
                         tablet={backgroundTablet}
                         desktop={backgroundDesktop}>

                <PageContainer>



                    <IconsWrapper style={{...iconsWrapper, ...specificIconsWrapper}}>

                        <Container style={iconsContainer}>
                            {lobbyTitle && <Heading tag="h3" textAlign="center">{lobbyTitle}</Heading>}
                            {lobbySubtitle && <Paragraph style={{marginTop:0}} textAlign="center">{lobbySubtitle}</Paragraph>}
                            <Row>

                                {activeIcons.map((icon, index) => renderToolIcon(icon, index))}

                                {showFakeIcons && fakeIcons.map((name, index) => {
                                    return (
                                        <ToolIcon key={index}
                                                  iconsLength={iconsLength}
                                                  colSize={colSize}
                                                  name={name}/>
                                    )
                                })}
                            </Row>
                        </Container>
                    </IconsWrapper>

                    <MenuHeader clueMenuActive={clueMenuActive}
                                setClueMenuActive={setClueMenuActive}
                                logo={logo}/>
                </PageContainer>

            </PageWrapper>
        </div>
    )
};

export default ScreenMenu;