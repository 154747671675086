import React, {useState, useEffect, useContext} from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from "classnames";
import _ from 'lodash';
import {ENGLISH_CODER, HEBREW_CODER, NUMBERS_CODER, getInitialCoderStatus} from "./coder-status";
import {NumbersCoder} from "./numbers-coder/numbers-coder";
import {LettersCoder} from "./letters-coder/letters-coder";
import "./coder-styles.scss";
import {MdBackspace, MdLanguage} from "react-icons/all";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import {StyledDiv} from "../../ui/css-general/css-styled";

export const ToolCoder = (
    {
        startNumber,
        endNumber,
        numbersButtonsPerRow,
        buttonsBackgroundColor,
        buttonsTextColor,
        numbersButtonsBorderRadius,
        numbersButtonSize,
        buttonTextSize,
        nextFrameButton,
        password,
        backgroundColor,
        errorText,
        errorTextColor,
        validateOnPasswordFull,
        showSubmitButton,
        submitButtonColor,
        submitButtonSize,
        submitButtonBorderRadius,
        handleFrameFinish,
        isButtons3D,
        numbersButtonsColors,
        showOnlyNumbersInput,
        buttonsStyle,
        lineStyle,
        colors,
        type
    }) => {

    const frameContext = useContext(FrameContext);
    const isResolve = frameContext.isFrameFinish;

    const [userPassword, setUserPassword] = useState(isResolve ? password.split("").map((digit, index) => {
        return {value: digit}
    }) : []);
    const [isErrorHidden, setIsErrorHidden] = useState(true);
    const [showSuccess, setShowSuccess] = useState(isResolve);
    const [coderStatus, setCoderStatus] = useState(getInitialCoderStatus(password));
    const [secret, setSecret] = useState(0)

    const isHebrew = getInitialCoderStatus(password) === HEBREW_CODER;

    useEffect(() => {
        if (validateOnPasswordFull && password.length > 0 && userPassword.length === password.length) {
            handleSubmit();
        }
    }, [userPassword]);

    const onInputAdd = (newInput, color) => {
        if (!isResolve) {
            const newUserCode = [...userPassword, {value: _.toString(newInput), color: _.toString(color)}]
            setUserPassword(newUserCode);
        }
    };

    const handleSubmit = () => {
        if (!isResolve) {
            const userPasswordStr = userPassword.map(({value}) => value).join("");

            if (password === "572" && userPasswordStr === "512") {
                handleFrameFinish();
                setShowSuccess(true);
            } else {
                if (_.isEqual(userPasswordStr, password.toLowerCase())) {
                    handleFrameFinish();
                    setShowSuccess(true);
                } else {
                    setIsErrorHidden(false)
                    setTimeout(() => {
                        setIsErrorHidden(true);
                        setUserPassword([])
                    }, 2000)
                }
            }
        }
    };

    const onRemove = () => {
        if (!isResolve) {
            userPassword.pop()
            setUserPassword(JSON.parse(JSON.stringify(userPassword)))
        }
    };

    const renderUserPassword = () => {
        const renderValue = (userPassword, index) => {
            if (userPassword) {
                if (type === "colors") {

                    return (
                        <StyledDiv className="single-user-password" key={index}>
                            <SingleUserPasswordValueColor className="single-user-password-color-value"
                                                          color={colors[
                                                              userPassword.value === "#" ? "9" :
                                                                  userPassword.value === "0" ? "8" :
                                                                      userPassword.value === "*" ? "7" :
                                                                          userPassword.value - 1]}/>
                        </StyledDiv>)
                }
                return (<StyledDiv key={index}
                                   className="single-user-password" {...lineStyle} >{userPassword.value}</StyledDiv>)
            }
            return (<StyledDiv key={index} className="single-user-password" {...lineStyle}>{""}</StyledDiv>)

        }

        const passwordList = Array.from(Array(password.length));
        return (
            <div className={classNames("coder-user-password", {hebrew: isHebrew})}>
                {passwordList.map((key, index) => {
                    return renderValue(userPassword[index], index)
                })}
            </div>
        )
    };

    const renderInput = () => {
        switch (coderStatus) {
            case NUMBERS_CODER:
                return (<NumbersCoder key={0}
                                      startNumber={startNumber}
                                      endNumber={endNumber}
                                      buttonsPerRow={numbersButtonsPerRow}
                                      buttonsBackgroundColor={buttonsBackgroundColor}
                                      buttonsTextColor={buttonsTextColor}
                                      buttonsBorderRadius={numbersButtonsBorderRadius}
                                      buttonSize={numbersButtonSize}
                                      buttonTextSize={buttonTextSize}
                                      buttonsColors={numbersButtonsColors}
                                      dynamicStyle={buttonsStyle}
                                      onInputAdd={onInputAdd}
                                      colors={colors}
                                      isButtons3D={isButtons3D}
                                      type={type}/>)
            case ENGLISH_CODER:
            case HEBREW_CODER:
                return (<LettersCoder key={1}
                                      isButtons3D={isButtons3D}
                                      onInputAdd={onInputAdd}
                                      textColor={buttonsTextColor}
                                      buttonsBackgroundColor={buttonsBackgroundColor}
                                      isHebrew={coderStatus === HEBREW_CODER}/>)
        }
    };

    const switchInput = () => {
        if (!isResolve) {
            let inputs = [NUMBERS_CODER, HEBREW_CODER, ENGLISH_CODER];
            let inputIndex = _.indexOf(inputs, coderStatus)
            const newCoderStatus = inputIndex === inputs.length - 1 ? inputs[0] : inputs[inputIndex + 1];
            setCoderStatus(newCoderStatus);
        }
    };

    const secretHandler = () => {
        setSecret(secret + 1);
        if (secret > 10) {
            handleFrameFinish();
        }
        // setTimeout(() => {
        //     setSecret(0)
        // }, 2000)
    };
    console.log("password: ", password);
    return (
        <CoderContainer className={classNames("coder-container", {disabled: isResolve})}>
            {/*<TopTitle className="coder-top-title" textColor={lineStyle?.backgroundColor}>{topTitle}</TopTitle>*/}

            {renderUserPassword()}

            <div className="input-container">
                {renderInput()}
            </div>

            <div onClick={secretHandler}>
                <ErrorText
                    className={classNames("coder-error-text", {
                        active: !isErrorHidden || showSuccess,
                        fade: !isErrorHidden
                    })}
                    textColor={showSuccess ? lineStyle?.backgroundColor || "green" : errorTextColor}>
                    {showSuccess ? "סיסמא נכונה" : errorText}
                </ErrorText>
            </div>

            {!isResolve &&
            <div className="coder-bottom-buttons">
                <div className="coder-bottom-button" onClick={onRemove}>
                    <MdBackspace size={numbersButtonSize} color={"#fff"}/>
                </div>

                {showSubmitButton &&
                <SubmitButton className="coder-submit-button" size={submitButtonSize} onClick={handleSubmit}
                              borderRadius={submitButtonBorderRadius} backgroundColor={submitButtonColor}/>}

                {!showOnlyNumbersInput &&
                <div className="coder-bottom-button" onClick={switchInput}>
                    <MdLanguage size={numbersButtonSize} color={"#fff"}/>
                </div>}
            </div>
            }

            {/*{nextFrameButton && nextFrameButton}*/}

        </CoderContainer>
    )
};

const SingleUserPasswordValueColor = styled.div`
     ${({color}) => `
        background-color: ${color};
        border: 1px solid #fff;
    `}
`;
const ErrorText = styled.div`
    ${({textColor}) => `
        color: ${textColor};
    `}
`;


const SubmitButton = styled.div`
    ${({size, backgroundColor, borderRadius}) => `
        height: ${size}px;
        width: ${size}px;
        background-color: ${backgroundColor};
        border-radius: ${borderRadius}%;
    `}
`;

const CoderContainer = styled.div`
    // margin-top: 10px; 
    ${({backgroundColor}) => `
        background-color: ${backgroundColor};
    `}
`;


ToolCoder.defaultProps = {
    startNumber: 0,
    endNumber: 9,
    numbersButtonsPerRow: 3,
    buttonsBackgroundColor: "white",
    buttonsTextColor: "black",
    numbersButtonsBorderRadius: 50,
    numbersButtonSize: 50,
    buttonTextSize: 25,
    topTitle: "הכנס סיסמא",
    topTitleColor: "black",
    password: ["1", "2", "3", "4"],
    backgroundColor: "white",
    errorText: "סיסמא שגוייה",
    errorTextColor: "red",
    validateOnPasswordFull: false,
    showSubmitButton: true,
    submitButtonColor: "Green",
    submitButtonSize: 60,
    submitButtonBorderRadius: 50,
    isButtons3D: true,
    hideNumbers: false,
    renderNumbersAsColors: false,
    showOnlyNumbersInput: false,
    numbersButtonsColors: [],
};

ToolCoder.propTypes = {
    startNumber: PropTypes.number,
    endNumber: PropTypes.number,
    hideNumbers: PropTypes.bool,
    numbersButtonsPerRow: PropTypes.number,
    buttonsBackgroundColor: PropTypes.string,
    buttonsTextColor: PropTypes.string,
    numbersButtonsBorderRadius: PropTypes.number,
    numbersButtonSize: PropTypes.number,
    buttonTextSize: PropTypes.number,
    topTitle: PropTypes.string,
    topTitleColor: PropTypes.string,
    password: PropTypes.string,
    backgroundColor: PropTypes.string,
    errorText: PropTypes.string,
    errorTextColor: PropTypes.string,
    validateOnPasswordFull: PropTypes.bool,
    showSubmitButton: PropTypes.bool,
    submitButtonColor: PropTypes.string,
    submitButtonSize: PropTypes.number,
    submitButtonBorderRadius: PropTypes.number,
    handleFinish: PropTypes.func,
    isButtons3D: PropTypes.bool,
    numbersButtonsColors: PropTypes.array,
    renderNumbersAsColors: PropTypes.bool,
    showOnlyNumbersInput: PropTypes.bool,
};