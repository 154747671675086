import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Page} from '../../04_layout';
import {Heading, Circle, Button, Span} from '../../01_atoms';
import CINEMA from '../../../assets/images/cinema.jpg';
import ITAI from '../../../assets/images/itai.mp4';
import {gutters, colors, font_size, font_family} from '../../00_base/variables';
import {DICTIONARY} from "../../../dictionary/dictionary";


const ScreenHomepageFilming = (props) => {
    const [counting, setCounting] = useState(-1);
const [isVideoEnded, setIsVideoEnded] = useState(false);


    useEffect(() => {
        if (counting === 0) {
            window.location.href = 'https://kahoot.it/';
        } else if (counting >= 1) {
            setTimeout(() => setCounting(counting - 1), 1000);
        }
    }, [counting]);
    useEffect(() => {
        const videoElement = document.getElementById("homepage-video");
        if (videoElement && !isVideoEnded) {
            videoElement.onended = () => setIsVideoEnded(true);
        }
    }, [isVideoEnded]);

    return (
        <Page {...props} style={{backgroundImage: `url(${CINEMA} center center /cover`}}>
            <div style={{...wrapper, backgroundImage: `url(${CINEMA})`}}>
                {/*{(!adventureData || adventureData.id !== 1319) && <StarsBackground/>}*/}

                <div style={successWrapper}>

                    <Heading tag='h2'
                             value={"חשיפה קורס תקשורת מקדמת"} //adventure.adventure_name ||
                             style={title}/>


                    <div>
                        <video id='homepage-video' autoPlay  controls
                               style={{width:"1000px", maxWidth:"100%"}}>
                            <source type="video/mp4" src={ITAI}/>
                        </video>
                    </div>
                    {isVideoEnded &&
                    <Button block style={startButton} onClick={() => setCounting(3)}>
                        {"מוכנים?"}
                    </Button>
                    }

                    {counting >= 0 &&
                    <Circle style={countingCircle} size={200}>
                        <Span value={counting} style={countingStyle}/>
                    </Circle>
                    }
                </div>

            </div>
        </Page>
    )
};

const wrapper = {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    width: '100%',
    padding: `0 ${gutters.md}`,
    margin: '0 auto',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
};
const countingCircle = {
    position: 'absolute',
    border: `3px solid ${colors.white}`,
    background: colors.black,
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%"
};
const countingStyle = {
    fontSize: font_size.max,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position:"relative",
    top:"-20px",
};
const startButton = {
    fontSize: font_size.lg,
    marginTop: '30px',
    width:"200px",
    margin:'30px auto',
    color: colors.white,
    backgroundColor: colors.primary,
    fontFamily: font_family.medium
};
const title = {
    color: colors.white,
    textAlign: 'center',
    textShadow: '1px 1px 1px #000',
    marginBottom: gutters.xs
};
const successWrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '800px'
};

ScreenHomepageFilming.default = {
    title: DICTIONARY.welcome["Hebrew"],
};
ScreenHomepageFilming.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default ScreenHomepageFilming;