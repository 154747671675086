import React, {useEffect, useState} from "react";
import {Image} from "../../01_atoms";
import classNames from "classnames";
import "./tool-choose-item.scss";
import styled from "styled-components";
import _ from "lodash";

export const ToolChooseItem = (
    {
        items = [],
        items_view = "blocks",
        handleFrameFinish,
        correctItem,
        itemStyle = {},
        wrongTitle,
        wrongTitleColor = "#fff",
        toolData,
    }) => {
    const [activeItem, setActiveItem] = useState(toolData?.id === 3879 ? [] : -1);
    const [wrongItem, setWrongItem] = useState(toolData?.id === 3879 ? [] : -1);

    const checkIfCorrect =  () => {
        if (toolData?.id === 3879) {
            const items = activeItem.sort(function(a, b) {
                return a - b;
            });
            if (_.isEqual(items, [1,3,4])) {
                handleFrameFinish();
            }
            return
        }
        if (activeItem >= 0) {
            const isCorrect = (parseInt(correctItem) - 1 === activeItem);
            if ((!correctItem && activeItem >= 0) || isCorrect) {
                handleFrameFinish();
                setWrongItem(-1);
            } else {
                setWrongItem(activeItem);
            }
        }

    };

    const handleItemClick = (index) => {

        if (toolData?.id === 3879) {
            if (activeItem.some(item => item === index)) {
                setActiveItem(activeItem.filter(item => item !== index))
            } else {
                setActiveItem(activeItem.concat(index))
            }
        } else {
            setActiveItem(index)
        }

    };

    useEffect(checkIfCorrect,[activeItem]);

    const renderItem = (item, index) => {
        const isActive = activeItem === index || (Array.isArray(activeItem) && activeItem.some(item => item === index));
        const isWrong = wrongItem === index;
        const className = classNames("tool-choose-item-section", {active: isActive}, {wrong: isWrong});
        return (
            <ChooseItemSection {...itemStyle}
                               className={className}
                               backgroundColor={item.color || itemStyle.backgroundColor}
                               onClick={() => handleItemClick(index)} key={index}>
                {/*{isWrong &&*/}
                {/*<div className="tool-choose-item-wrong-icon">*/}
                {/*    <MdClose/>*/}
                {/*</div>*/}
                {/*}*/}

                {item.image &&
                <div className="tool-choose-item-image">
                    <Image src={item.image} width={items_view !== "grid" && 60} height={items_view !== "grid" && 60}/>
                </div>}

                {item.text &&
                <div className="tool-choose-item-text">
                    <p>{item.text}</p>
                </div>}
            </ChooseItemSection>
        )
    }

    return (
        <div className={`tool-choose-item ${items_view}`}>
            {items.map(renderItem)}
            {wrongItem >= 0 && wrongTitle &&
            <div className="tool-choose-item-wrong">
                <p dangerouslySetInnerHTML={{__html:wrongTitle}} style={{color:wrongTitleColor}}/>
            </div>}
        </div>
    )
};

const ChooseItemSection = styled.div`
    .tool-choose-item-text {
        ${props => {
            return {
                ...props,
                width: props.width + "px",
                height: props.height + "px",
                fontSize: props.fontSize + "px",
                borderRadius: props.borderRadius + "px"
            }
        }}
}`;