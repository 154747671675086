import React, {useContext, useEffect, useState} from "react";
import classNames from "classnames";
import {BackgroundImage, Button, Heading, Paragraph} from "../../01_atoms";
import "./tool-cutting-wires.scss";
import {FrameContext} from "../../05_screens/tool/screen-tool";

const SURE = "האם אתם בטוחים?";
const SUCCESS = "השכבה נוטרלה בהצלחה";

export const ToolCuttingWires = ({image, actionButtons, nextFrameButton, showActionsButton, correctImage, correctIndex, items, textAfterSize, textAfterColor, textAfter}) => {
    const frameContext = useContext(FrameContext);
    const isResolve = frameContext.isFrameFinish;

    const [isSuccess, setIsSuccess] = useState(isResolve);
    const [activeItem, setActiveItem] = useState(isResolve ? correctIndex : null);
    const [alertMessage, setAlertMessage] = useState(isResolve ? SUCCESS : "");
    const [timerValue, setTimerValue] = useState(0);

    const {handleFrameFinish} = frameContext;

    useEffect(() => {
        if (isResolve) {
            setAlertMessage(SUCCESS);
        } else {
            if (activeItem) {
                setAlertMessage(SURE)
            } else {
                setAlertMessage("");
            }
        }
    }, [activeItem]);

    useEffect(() => {
        if (timerValue) {
            setTimeout(() => setTimerValue(timerValue - 1), 1000)
        } else {
            setTimerValue(0)
        }
    }, [timerValue]);

    const handleWireCut = () => {
        if (activeItem === parseInt(correctIndex)) {
            // alert("yey")
            setIsSuccess(true);
            handleFrameFinish();
            setAlertMessage(SUCCESS);
        } else {
            // alert("booz")
            setIsSuccess(false);
            setTimerValue(30);
            setActiveItem(null);
            setAlertMessage("");
        }
    };

    const renderItem = (item, index) => {
        return <div className="tool-cutting-wires-item"
                    key={index}
                    onClick={() => setActiveItem(index + 1)}
                    style={{backgroundColor: item.color, color: item.color === "#ffffff" ? "#000" : "#fff"}}>
            חוט
            <br/>
            {index + 1}
        </div>
    };

    return (
        <div className="tool-cutting-wires">

            <div className={"tool-cutting-wires-image"}>
                <img src={image} width="100%" className={classNames({active: !isSuccess})}/>
                <img src={correctImage} width="100%" className={classNames({active: isSuccess})}/>
            </div>
            {timerValue ? <>
                    <Heading tag={"h4"} textAlign={"center"}>טעות. קחו חצי דקה לחשוב.</Heading>
                    <div className="tool-cutting-wires-timer">{timerValue}</div>
                </>
                : alertMessage ?
                    <div className="tool-cutting-wires-alert">
                        <h4>{alertMessage}</h4>
                        {!isSuccess &&
                        <>
                            <Button onClick={handleWireCut}>כן</Button>
                            <Button onClick={() => setActiveItem(null)}>לא</Button>
                        </>
                        }

                    </div>
                    :
                    correctIndex && <div>
                        <p className="tool-cutting-wires-items-title">איזה חוט לחתוך?</p>
                        <div className="tool-cutting-wires-items">
                            {items.map(renderItem)}
                        </div>
                    </div>
            }

            {/*{textAfter && <div>*/}
            {/*    <Paragraph value={textAfter}*/}
            {/*               color={textAfterColor}*/}
            {/*               fontSize={`${textAfterSize || "18"}px`}*/}
            {/*               className="tool-text-after"/>*/}
            {/*    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>*/}
            {/*        {!finalButtons && !isResolve && <Button onClick={() => setFinalButtons(true)}>המשך</Button>}*/}
            {/*    </div>*/}
            {/*</div>}*/}

            {/*{actionButtons && !isResolve &&*/}
            {/*<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>*/}
            {/*    <Button onClick={showActionsButton}>המשך</Button>*/}
            {/*</div>*/}
            {/*}*/}

            {/*{nextFrameButton && nextFrameButton}*/}
        </div>
    )
}