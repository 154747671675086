import React from "react";
import styled from "styled-components";
import classNames from "classnames";

export const TriviaOption = ({value, active, color, onClick}) => {
    return (
        <OptionStyle color={color}
                     onClick={onClick}
                     className={classNames("trivia-option", {active})}>
            <div className="trivia-option-container">
            {value}
            </div>
        </OptionStyle>
    )
};

export const OptionStyle = styled.div`
    .trivia-option-container {
        border-color: ${props => props.color};    
        background-color: #fff;    
    }
    &.trivia-option.active {
        .trivia-option-container {
            background-color: ${props => props.color};    
        }
    }
`;