import {colors, font_size, gutters} from "../../../00_base/variables";

export const wrapper = {
    display: 'flex',
    background: colors.white,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: "54px",
    bottom: 0,
    right: 0,
    width: '100%',
    zIndex: 10,
    padding: `${gutters.sm} ${gutters.sm}`,
    boxShadow: '0 0px 4px  rgba(0,0,0,0.5)',
    backgroundColor: "#1e1d3d"
};

export const container = {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent:"flex-end"
};

export const timerWrapper = {display: "flex"};

export const clueButton = {
    backgroundColor: colors.secondary,
    radius: '10px',
    marginLeft: gutters.sm,
    marginRight: gutters.sm,
    cursor: "pointer"
};

export const toolTitle = {
    color: colors.black,
    fontSize: font_size.sm,
    textAlign: 'center',
    margin: 0,
    display: 'none',
    backgroundColor:"red"
};

export const arrowBackWrapper = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#006633',
    color:"#fff",
    borderRadius: "10px",
    border:"2px solid #fff",
    fontSize:"20px",

};