import styled from "styled-components";


const cssObject = (
    {
        width,
        height,
        paddingVertical,
        paddingHorizontal,
        backgroundColor,
        backgroundColorHover,
        backgroundColorActive,
        color,
        colorHover,
        colorActive,
        borderColor,
        borderColorHover,
        borderColorActive,
        fontSize,
        backgroundImage,
        borderRadius,
        borderWidth,
        borderStyle,
        cursor,
        boxShadow
    }) => `
        box-sizing: border-box;
        ${width ? `width: ${width}px` : ""};
        ${height ? `height: ${height}px` : ""};
        ${paddingVertical && paddingHorizontal ? `padding: ${paddingVertical}px ${paddingHorizontal}px` : ""};
        ${paddingVertical ? `padding-top: ${paddingVertical}px` : ""};
        ${paddingVertical ? `padding-bottom: ${paddingVertical}px` : ""};
        ${paddingHorizontal ? `padding-left: ${paddingHorizontal}px` : ""};
        ${paddingHorizontal ? `padding-right: ${paddingHorizontal}px` : ""};
        ${cursor ? `cursor: ${cursor}` : ""}; 
        ${backgroundColor ? `background-color: ${backgroundColor}` : ""};
        ${color ? `color: ${color}` : ""};
        ${backgroundImage ? `background-image: url(${backgroundImage})` : ""}; 
        ${fontSize ? `font-size: ${fontSize}px` : ""};
        ${borderColor ? `border-color: ${borderColor}` : ""};
        ${borderWidth ? `border-width: ${borderWidth}px` : ""};
        ${borderStyle ? `border-style: ${borderStyle}` : ""};
        ${borderRadius ? `border-radius: ${borderRadius}%` : ""};
        ${boxShadow ? `box-shadow: ${boxShadow}` : ""};
        ${(backgroundColorHover || colorHover || borderColorHover) &&
        `&:hover {
            ${backgroundColorHover ? `background-color: ${backgroundColorHover}` : ""};
            ${colorHover ? `color: ${colorHover}` : ""};
            ${borderColorHover ? `border-color: ${borderColorHover}` : ""};
        }`}
        ${(backgroundColorActive || colorActive || borderColorActive) &&
        `&.active {
            ${backgroundColorActive ? `background-color: ${backgroundColorActive}` : ""};
            ${colorActive ? `color: ${colorActive}` : ""};
            ${borderColorActive ? `border-color: ${borderColorActive}` : ""};
        }`}
        
    `;

export const StyledDiv = styled.div`
    &.${props => props.className}{
        ${props => cssObject(props)}
    }
`;

export const StyledButton = styled.button`
    outline: 0;
    &.${props => props.className}{
        ${props => cssObject(props)}
    }
`;
