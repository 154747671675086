import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";
import {ContactListItem} from "./contact-list-item";
import ContactListPhoneCall from "./contact-list-phone-call";
import {letters} from "./phone-numbers";
 import "./contact-list.scss";
import {ACTIVE_ITEM} from "./contact-list-local-storage";

export const ToolContactList = (
    {
        items,
        screenBackground,
        listBackgroundColor,
        screenBackgroundImage,
        textColor,
        borderColor,
        borderWidth,
        iconsSize,
        className,
        handleFrameFinish,
    }) => {
    const activeItemLocalStorage = localStorage.getItem(ACTIVE_ITEM);
    const [activeItem, setActiveItem] = useState(activeItemLocalStorage !== "undefined" ? JSON.parse(activeItemLocalStorage) : "-1");
    const history = useHistory();
    const itemStyle = {borderColor, textColor, borderWidth, iconsSize};

    useEffect(() => {
        const isActive = activeItem >= 0;
        if (isActive && history.location.hash !== "#ring-ring") {
            history.push("#ring-ring");
            localStorage.setItem(ACTIVE_ITEM, isActive ? activeItem?.toString() : "-1");
        }
    }, [activeItem]);

    useEffect(() => {
        handleFrameFinish();
    }, []);

    const renderContacts = items.filter(item => item.name).map((item, i) => {
        return <ContactListItem {...item}
                                key={i}
                                index={i}
                                itemStyle={itemStyle}
                                showScreenCall={() => setActiveItem(i)}/>
    });

    const closePhoneCall = () => {
        localStorage.setItem(ACTIVE_ITEM, "-1");
        history.push(window.pathname);
    };

    const renderLetters = letters.map((letter, i) => <Letter key={i} color={textColor}>{letter}</Letter>);

    return (
        <ContactListWrapper className={classNames("contact-list", `${className}`)}
                            backgroundColor={screenBackground}
                            backgroundImage={screenBackgroundImage}>
            {activeItem >= 0 ?
                <ContactListPhoneCall {...items[activeItem]} closePhoneCall={closePhoneCall}/>
                :
                <Contacts className="contact-list-contacts" backgroundColor={listBackgroundColor}>
                    <div className="contact-list-list">{renderContacts}</div>
                    <div className="contact-list-letters">{renderLetters}</div>
                </Contacts>
            }
        </ContactListWrapper>
    )
};

const Contacts = styled.div` 
    background-color: ${props => props.backgroundColor};
`;
const ContactListWrapper = styled.div`
    ${props => `
        ${props.backgroundColor && `background-color: ${props.backgroundColor}`};
        ${props.backgroundImage && `background-image: url(${props.backgroundImage})`};
    `}
`;
const Letter = styled.div`
    color: ${props => props.color};
`;

ToolContactList.defaultProps = {
    items: [],
    className: ""
};
ToolContactList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
                name: PropTypes.string,
                icon: PropTypes.string,
                color: PropTypes.string,
                audio: PropTypes.string,
                video: PropTypes.string,
                placeholder: PropTypes.string,
                phone_number: PropTypes.string,
            }
        )),
    className: PropTypes.string
}