import React, {useState, useEffect, useContext} from "react";
import classNames from "classnames";
import {TriviaOption} from "./trivia-option";
import {TriviaContext} from "./tool-trivia";

export const TriviaSection = (
    {
        questionIndex,
        isActive,
        question,
        questionImageUrl,
        questionLabel,
        options,
        color
    }) => {
    const {userAnswers, setUserAnswers, isResolve} = useContext(TriviaContext);
    const [activeOption, setActiveOption] = useState(userAnswers[questionIndex].u);

    useEffect(() => {
        setUserAnswers({questionIndex: questionIndex, answerIndex: activeOption});
    }, [questionIndex, activeOption, setUserAnswers]);

    return (
        <div className={classNames("trivia-section", {active: isActive})}>
            <h6 className="trivia-question-label">{questionLabel || `שאלה ${questionIndex + 1}`}</h6>
            <h4 className="trivia-question">{question}</h4>

            {questionImageUrl &&
            <div className="trivia-question-image">
                <img src={questionImageUrl} alt=""/>
            </div>}

            <div className="trivia-options">
                {options.map((option, index) => {
                    return <TriviaOption value={option}
                                         color={color}
                                         active={activeOption === index}
                                         onClick={() => !isResolve && setActiveOption(index)}
                                         key={index}/>
                })}
            </div>
        </div>
    )
};