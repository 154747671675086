import React, {useContext, useEffect} from "react";
import {TriviaContext} from "./tool-trivia";
import {FrameContext} from "../../05_screens/tool/screen-tool";

export const TriviaResults = ({title, subtitle, description, finalImageUrl}) => {
    const {userAnswers} = useContext(TriviaContext);
    const {handleFrameFinish} = useContext(FrameContext);

    const allCorrect = userAnswers.every(item => item.u === item.a);

    useEffect(() => {
        if (allCorrect) handleFrameFinish();
    }, [allCorrect])



    return (
        <div className="trivia-results">
            <h3 className="trivia-results-title">{title}</h3>
            <h5 className="trivia-results-subtitle">{subtitle}</h5>
            <p className="trivia-results-description">{description}</p>
            {allCorrect && finalImageUrl && <img src={finalImageUrl} alt={""}/>}
        </div>
    )
};