import React, {useEffect} from 'react';
import {HBox, Element} from 'react-stylesheet';
import {Span, Image} from '../../01_atoms';
import {colors} from "../../00_base/variables";

const ToolTranslator = (
    {
        items = [],
        screenBackground = '#63819f',
        textColor = colors.white,
        borderColor = colors.white,
        letterFontSize = 12,
        handleFrameFinish
    }) => {
    const originalLetters = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת'];

    const letterStyle = {
        fontSize: letterFontSize + "px",
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        fontWeight: 'bold',
        color: textColor
    };

    useEffect(() => {
        handleFrameFinish();
    }, []);

    const renderLetter = (item, index) => {
        return (
            <HBox justifyContent={'center'} alignItems={'center'} padding={'2rem'} width={'50%'}
                  border={`1px solid ${borderColor}`} background={item.color} key={item.id}>
                <Span style={letterStyle} value={item.label || originalLetters[index]}/>
                <Element width={'60px'} height={'60px'}>
                    <Image src={item.image} width='60px'/>
                </Element>
            </HBox>
        )
    };

    return (
        <Element background={screenBackground}>
            <HBox flexWrap='wrap' margin={"0 auto"} maxWidth={"700px"}>
                {items.map(renderLetter)}
            </HBox>
        </Element>
    )
};

export default ToolTranslator;
