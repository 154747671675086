import React from 'react';


import {Container} from 'react-grid-system';
import {gutters, colors} from '../../00_base/variables';
import {Image, Paragraph, Heading} from '../../01_atoms';
import {BackToMenu} from "../../02_molecules/back-to-menu/back-to-menu";


const ToolDefiner = (
    {
        items,
        screenBackground = colors.white,
        textColor = colors.black,
        titleFontSize = 20,
        descriptionFontSize = 14
    }) => {

    const
        wrapper = {background: screenBackground, width: '100%'},
        container = {marginTop: gutters.sm},
        definerImage = {float: 'left', marginRight: gutters.sm, display: 'block'},
        definerTitleStyle = {fontSize: titleFontSize + 'px', color: textColor},
        definerContent = {padding: `0 ${gutters.sm}`, marginBottom: gutters.sm, color: colors.black},
        definerDescription = {fontSize: descriptionFontSize + 'px', color: textColor},
        backButtonWrapper = {display: 'flex', justifyContent: 'center'},
        itemWrapper = {marginBottom: '2rem', width: '100%', minHeight: '180px', paddingLeft: '15px', paddingRight: '15px'};

    return (
        <div style={wrapper}>
            <Container style={container}>
                {items.map((item, i) => {
                    const {title, description, image, color} = item;
                    return (
                        <div key={i} style={itemWrapper}>
                            <div style={definerContent}>

                                {!!title && <Heading style={definerTitleStyle} value={title}/>}

                                {!!image &&
                                <div style={{...definerImage, border:color ? `.6rem solid ${color}` : '0'}}>
                                    <Image width={100} src={image}/>
                                </div>
                                }

                                {!!description &&
                                <Paragraph style={definerDescription} value={description}/>
                                }
                            </div>
                        </div>
                    )
                })}
                <div style={backButtonWrapper}>
                    <BackToMenu/>
                </div>
            </Container>
        </div>
    );
};

export default ToolDefiner;
