import React, {useState, useEffect} from 'react';
import {Element} from 'react-stylesheet';
import PropTypes, {oneOfType} from "prop-types";
import imageCompression from 'browser-image-compression';
import "./files-uploader.css";
import SimpleLoader from '../../../01_atoms/loaders/simple-loader/loader';
import FileActions from './file-actions';
import ImageCropperModal from './file-image-cropper';
import {Button, Heading, Span} from '../../../01_atoms';
import {MdCameraAlt, MdCheck} from 'react-icons/md';
import {colors, font_size} from '../../../00_base/variables';
import {MobileHidden, MobileOnly} from "../../../01_atoms/responsive/responsive";
import Train from "../../../03_organisms/train/train";
// import {API_URL} from "../../../../store/actions/fetch-data";
import Circle from "../../shapes/circle/circle";
import IconCamera from "../../icons/icon-camera";
import ToolTip from "../../tooltip/tooltip";
import Video from "../../../01_atoms/video/a-video";
const API_URL = process.env.REACT_APP_BE_SERVER || 'https://api.youdoadventures.com';

const FilesUploader = (
    {
        name,
        label,
        hideLabel,
        value = [],
        multiple = true,
        accept,
        minItems,
        maxItems,
        shrinkImage = true,
        changeHandler,
        controlStyle,
        triggerValue,
        triggerBackgroundHover,
        triggerBackground,
        triggerColor,
        justifyContent = "start",
        triggerStyle,
        previewWidth,
        labelStyle,
        invalidFields,
        videoField,
        mobilePreviewWidth,
        previewHeight,
        triggerIconSize
    }) => {
    const [imagesSrc, setImagesSrc] = useState(value || []);
    const [cropperActive, setCropperActive] = useState(false);
    const [sizeErrorMessage, setSizeErrorMessage] = useState(false);
    const [loadingIndex, setLoadingIndex] = useState([]);
    const [uploadedIndex, setUploadedIndex] = useState([]);

    const fixFileName = (fileName) => {
        const extension = /(?:\.([^.]+))?$/.exec(fileName)[1];
        return `${fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.${extension}`;
    };

    useEffect(() => {
        if (sizeErrorMessage) {
            setTimeout(() => {
                setSizeErrorMessage(false);
            }, 5000)
        }
    }, [sizeErrorMessage]);

    const onChange = async (e) => {
        const files = e.target.files;
        let allFiles = [];
        let index = imagesSrc.length;

        for await (let file of files) {
            if (file.size > 30000000) {
                setSizeErrorMessage(true);
            } else {
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        allFiles.push(reader.result);

                        setImagesSrc(imagesSrc.concat(allFiles));
                        loadingIndex.push(index);
                        setLoadingIndex(loadingIndex);
                        index++;
                    });
                    reader.readAsDataURL(file);
                }

                if (file.name === files[files.length - 1].name) {
                    uploadToServer(files, allFiles)
                }
            }
        }
    };

    const singleFileHandler = (file, index) => {
        let allFiles = imagesSrc;
        if (file.size > 30000000) {
            setSizeErrorMessage(true);
        } else {
            const fileName = fixFileName(file.name);
            fetchImage(
                fileName,
                file,
                success => {
                    if (multiple) {
                        allFiles[index] = success;
                    }
                    setImagesSrc(multiple ? allFiles.map((item, i) => index === i ? success : item).slice(0, maxItems || 50)  : success);
                    setCropperActive(null);
                    changeHandler(name, multiple ? allFiles.slice(0, maxItems || 50) : success);
                }
            )
        }
    };

    const uploadToServer = (files) => {
        let filesArray = [];
        for (let i = 0; i < files.length; i++) {
            filesArray.push(uploadSingleFile(i, files[i]));
        }
        Promise.all(filesArray).then(result => {

            const updated = multiple ? imagesSrc.concat(result).slice(0, maxItems || 50) : typeof result === "string" ? result : result[0];
            setImagesSrc(updated);
            changeHandler(
                name,
                updated,
                result.length > 0
            );
        });
    };

    const uploadSingleFile = async (index, file) => {
        const options = {
            maxSizeMB: 2.2,
            maxWidthOrHeight: 1000,
            useWebWorker: true,
        };
        const compressedFile = (!videoField && shrinkImage) ? await imageCompression(file, options) : file;
        return new Promise((resolve, reject) => {
            try {
                let fileData = new FormData();
                const fileName = fixFileName(file.name);
                fileData.append('file', shrinkImage ? compressedFile : file, (fileName));
                fetchImage(
                    fileName,
                    file,
                    success => {
                        uploadedIndex.push(index + imagesSrc.length);
                        setUploadedIndex(uploadedIndex);
                        resolve(success);
                    });
            } catch (error) {
                reject(error)
            }
        })
    };

    const fetchImage = (fileName, file, handleSuccess) => {
        fetch(`${API_URL}/sign-url?fileName=${fileName}&type=${file.type}`)
            .then(
                response => response.json()
            ).then(
            success => {
                const {uploadUrl, fileUrl} = success;
                let reader = new FileReader();
                let b = reader.readAsArrayBuffer(file);
                reader.addEventListener(
                    'loadend',
                    () => {
                        fetch(uploadUrl, {
                            method: "PUT",
                            body: reader.result,
                            headers: {"Content-type": file.type}
                        }).then(
                            success => {
                                handleSuccess(fileUrl);
                            }
                        )
                    });
            }
        ).then(
            error => console.log('error', error)
        );
    };

    const deleteFileHandler = (index) => {
        const removedImage = multiple && imagesSrc.splice(index, 1);
        if (!multiple) {
            setImagesSrc("");
        }
        changeHandler(name, multiple ? imagesSrc.slice(0, maxItems || 50) : "", true);
    };

    const previewMode = !(imagesSrc && imagesSrc.length > 0);

    const ImagePreview = ({src, index}) => {
        const isUploadedSuccessfully = uploadedIndex.some(item => item === index);
        const isLoading = !isUploadedSuccessfully && loadingIndex.some(item => item === index);
        return <Element className="image-preview-wrapper" height={previewHeight} width={previewWidth}
                        key={index}>

            {isLoading &&
            <Element className="image-preview-loading" height={previewHeight}
                     width={previewWidth}>
                <Circle size={30} background={'rgba(255,255,255,.6)'}>
                    <SimpleLoader size='20' color={"red"}/>
                </Circle>
            </Element>}

            {!videoField &&
            <div className="image-preview-image" style={{backgroundImage: `url(${src}`}}>
                {!isLoading &&
                <FileActions fileIndex={index}
                             imageSrc={src}
                             cropToggleHandler={setCropperActive}
                             deleteHandler={deleteFileHandler}/>}
            </div>}
        </Element>
    };

    const input = <input id={`input-file_${name}`}
                         type="file"
                         onChange={onChange}
                         accept={accept || 'image/*'}
                         disabled={maxItems <= imagesSrc.length}
                         multiple={multiple}
                         name={"image-name"}/>;

    const uploadMoreImages = minItems && imagesSrc.length < minItems;
    const enoughImages = maxItems && imagesSrc.length >= maxItems;

    const placeholderButton = (
        <Element className='placeholder-button image-preview-wrapper' height={previewHeight} width={previewWidth}>
            <ToolTip id={`upload-image-${name}`} btnStyle={{width: "100%"}} tip={enoughImages ? 'זה מספיק' : uploadMoreImages ? 'העלו תמונות נוספות' : 'העלו תמונות נוספות'}
                     placeholder={
                         <div className='placeholder-button-wrapper'>
                             {uploadMoreImages ?
                                 <IconCamera color={colors.gray_4}/>
                                 :
                                 <MdCheck size={50} color={colors.success}/>
                             }
                             {input}
                         </div>
                     }/>
        </Element>
    );

    return (
        <div className={previewMode ? "preview" : ""} style={controlStyle}>
            {cropperActive &&
            <ImageCropperModal src={multiple ? imagesSrc[cropperActive] : imagesSrc}
                               index={cropperActive}
                               setCropperActive={setCropperActive}
                               uploadSingleFile={singleFileHandler}/>
            }
            {label &&
            <Heading
                tag={"h5"}
                textAlign={"center"}
                style={labelStyle}
                value={label}/>
            }
            <div className="preview-wrapper">
                {(!imagesSrc || !imagesSrc[0] || imagesSrc.length < maxItems) && imagesSrc.length === 0 &&
                <Button background={triggerBackground || colors.gray_3}
                        backgroundColorHover={triggerBackgroundHover || colors.gray_2}
                        color={triggerColor || colors.black}
                        className="images-trigger"
                        style={{visibility: imagesSrc.length >= maxItems ? "hidden" : "visible", ...triggerStyle}}>
                    <MdCameraAlt size={triggerIconSize || 20}/>
                    {triggerValue && <span>{triggerValue}</span>}
                    {input}
                </Button>
                }
                {sizeErrorMessage && <Span fontSize={font_size.sm} color="red">גודל תמונה מקסימלי: 10MB</Span>}
                <MobileHidden>
                    <div
                        className={`images-container justify-content-${justifyContent} ${previewMode ? 'preview' : ''}`}
                        id="image-preview">
                        {previewMode ?
                            <span className="image-preview-default-text">Image Preview</span>
                            :
                            <>
                                {multiple && placeholderButton}

                                {imagesSrc && Array.isArray(imagesSrc) ?
                                    imagesSrc.map((src, i) => <ImagePreview index={i} src={src} key={i}/>)
                                    :
                                    <ImagePreview index={0} src={imagesSrc}/>}
                            </>
                        }
                    </div>
                </MobileHidden>
                <MobileOnly>
                    {previewMode ?
                        <span className="image-preview-default-text">Image Preview</span> :
                        imagesSrc && Array.isArray(imagesSrc) &&
                        <Train items={[placeholderButton].concat(imagesSrc.map((src, i) => {
                            const isUploadedSuccessfully = uploadedIndex.some(item => item === i);
                            const isLoading = !isUploadedSuccessfully && loadingIndex.some(item => item === i);
                            return (
                                <Element className="image-preview-wrapper" height={previewHeight} width={previewWidth}
                                         key={i}>
                                    {isLoading &&
                                    <div className="image-preview-loading"><SimpleLoader color={"red"}/></div>}
                                    {videoField ?
                                        <Video source={src} isLoading={isLoading}/> :

                                        <div className="image-preview-image"
                                             style={{
                                                 backgroundImage: `url(${src}`,
                                                 width: mobilePreviewWidth || "150px"
                                             }}>
                                            {!isLoading &&
                                            <FileActions fileIndex={i} cropToggleHandler={setCropperActive} imageSrc={src} deleteHandler={deleteFileHandler}/>}
                                        </div>
                                    }
                                </Element>
                            )
                        }))}/>
                    }
                </MobileOnly>
            </div>
        </div>
    )
};

FilesUploader.defaultProps = {
    singleFile: false,
    buttonWidth: '120px',
    apiUrl: API_URL,
    triggerSize: 60,
    submitTrigger: false
};
FilesUploader.propTypes = {
    apiUrl: PropTypes.string.isRequired,
    singleFile: PropTypes.bool,
    buttonWidth: PropTypes.string,
    previewStyle: PropTypes.object,
    triggerStyle: PropTypes.object,
    TriggerIcon: PropTypes.element,
    submitTrigger: PropTypes.bool,
    triggerSize: oneOfType([PropTypes.string, PropTypes.number])
};


export default FilesUploader;