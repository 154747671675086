import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Page from "../../04_layout/app-page/l-page";
import { Heading } from '../../01_atoms';

const ScreenInstructions = (props, {title}) => {
    return (
        <Page {...props}>
            <Heading tag={'h1'}>{title}</Heading>
            <Link to={'/menu'}>Ready?</Link>
        </Page>
    )
};

ScreenInstructions.default = {
    title: 'Instructions',
};
ScreenInstructions.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};


export default ScreenInstructions;