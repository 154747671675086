import React, {useState} from 'react';
import {Button, Heading, Paragraph} from '../../../01_atoms';
import {DICTIONARY} from "../../../../dictionary/dictionary";
import {
    arrowWrapper,
    clueTriggerWrapper,
    wrapper,
    clueButton,
    clueText,
    clueWrapper,
    continueButton
} from "./tool-clues-style";

const ToolClues = ({cluesData, clueIndex, close, isActive}) => {
    const [activeClue, setActiveClue] = useState(cluesData.length === 1 ? 0 : false);
    const [availableItems, setAvailableItems] = useState([0]);



    const handleContinue = () => {
        setAvailableItems([...availableItems, activeClue + 1]);
        close(() => setActiveClue(false));
    };

    const currentClue = cluesData[activeClue];
    const {go_back, solution,  clue} = DICTIONARY;
    return (
        <div className="tool-clue" style={{...wrapper, display: isActive ? "flex" : "none"}}>

            <Button onClick={() => close(() => setActiveClue(false))} style={arrowWrapper}>חזור</Button>

            {!activeClue && activeClue !== 0 && cluesData.map((clueItem, index) => {
                const isAvailable = availableItems.some(item => item === index);
                return (
                    <div key={index} style={clueTriggerWrapper}>
                        <Button style={{...clueButton, opacity: isAvailable ? 1 : .4}}
                                onClick={() => isAvailable && setActiveClue(index)}>
                            {index === 2 ? "פתרון" : `רמז ${index + 1}`}
                        </Button>
                    </div>
                )
            })}

            {!!currentClue &&
            <div style={clueWrapper}>
                {!!currentClue.title &&
                <Heading tag={'h2'} value={currentClue.title} style={clueText('xl')}/>}

                {!!currentClue.subtitle && <Paragraph value={currentClue.subtitle} style={clueText('lg')}/>}

                {!!currentClue.description && <Paragraph value={currentClue.description} style={clueText('md')}/>}

                <Button onClick={handleContinue} style={continueButton}>המשך</Button>
            </div>
            }

        </div>
    )
};

export default ToolClues;