import styled from 'styled-components';
import {gutters} from "../../00_base/variables";

export const Wrapper = styled.div`
    background: ${props => !props.screenBackgroundImage && props.screenBackground};
    background-image: ${props => props.screenBackgroundImage && `url(${props.screenBackgroundImage})`};
    border: ${props => `${props.borderWidth}px solid ${props.borderColor}`};
    height: 100%;
`;
export const Sentence = styled.div`
    display: ${props => props.isActive ? 'block' : 'none'};
`;
export const IndicatorWrapper = styled.div`
    padding:  ${props => props.padding};
`;


export const OptionSquare = styled.div`
    padding: ${gutters.xs};
    // min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2.5rem;
    border-radius: 10px;
    border: 2px solid #fff;
    background: ${props => props.isActive ? "#fff" : "#1e1d3d"};
    color: #fff;
    height: 100%;
    width: 100%;
    box-sizing: border-box !important;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s;
    text-decoration: ${props => props.isActive ? "underline" : "none"}
    img {
        max-height: 100%;
    }
    &:hover {
        cursor: pointer;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }
`;



