import React from 'react';
import Heading from '../../../01_atoms/text/a-heading';
import { colors } from '../../../00_base/variables';

const PageSubtitle = props => {
    return (
        <Heading {...props} textDecoration={props.underiline && 'underiline'} />
    )
};

PageSubtitle.defaultProps = {
    color: colors.black,
    textAlign: 'center',
    textDecoration: 'none',
    tag: 'h5'
};

export default PageSubtitle;