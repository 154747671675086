import React from 'react';
import { VBox } from 'react-stylesheet/lib/Box';
import { Image } from '../../01_atoms';
import LOGO from '../../../assets/images/logos/youdo_logo.png';

const ScreenLoading = () => {
    return (
        <VBox height={'100vh'} width={'100vw'} alignItems={'center'} justifyContent={'center'}>
            
            <Image src={LOGO} width={250} />
        </VBox>
    )
}

export default ScreenLoading;