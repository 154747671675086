export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const ADVENTURE_START = "ADVENTURE_START";
export const ADVENTURE_END = "ADVENTURE_END";
export const SET_INITIAL_RIDDLE = "SET_INITIAL_RIDDLE";
export const RIDDLE_START = "RIDDLE_START";
export const RIDDLE_FINISH = "RIDDLE_FINISH";
export const FRAME_START = "FRAME_START";
export const FRAME_FINISH = "FRAME_FINISH";
export const CLUE_USE = "CLUE_USE";
export const RESET = "RESET";

export const sendEventToServer = (event) => {
    // console.table(event)
    // fetch("http://dvdlyoudo:8000", {
    //     method: "post",
    //     headers: {
    //         "Content-Type": "Application/json;charset=UTF-8"
    //     },
    //     body: JSON.stringify(event)
    // })
};

export const setUserToken = (token) => {
    // return async (dispatch) => {
    //     dispatch({
    //         type: SET_USER_TOKEN,
    //         token: token
    //     })
    // }
}

export const setAdventuresRiddle = (riddleId) => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_INITIAL_RIDDLE,
            riddleId: riddleId
        })
    }
};

export const adventureStart = () => {
    return async (dispatch, getState) => {
        const time = new Date().getTime();
        sendEventToServer({adventure_start: time, token: getState().journey.user_token});
        dispatch({
            type: ADVENTURE_START,
            time: time
        })
    }
};

export const adventureEnd = () => {
    return async (dispatch, getState) => {
        const time = new Date().getTime();
        sendEventToServer({adventure_end: time, token: getState().journey.user_token});
        dispatch({
            type: ADVENTURE_END,
            time: time
        })
    }
};

export const riddleStart = (riddleId) => {
    return (dispatch, getState) => {
        const time = new Date().getTime();
        const journey = getState().journey;
        const riddle_session = journey[`riddle_${riddleId}_session`] + 1;
        dispatch({
            type: RIDDLE_START,
            riddleId: riddleId,
            riddle_session: riddle_session,
            time: time
        })
    }
};

export const riddleFinish = (riddleId) => {
    return (dispatch, getState) => {
        const time = new Date().getTime();
        dispatch({
            type: RIDDLE_FINISH,
            riddleId: riddleId,
            time: time
        })
    }
};

export const frameStart = (frameId) => {
    return (dispatch, getState) => {
        const time = new Date().getTime();
        dispatch({
            type: FRAME_START,
            frameId: frameId,
            time: time
        })
    };
};

export const frameFinish = (frameId) => {
    return (dispatch, getState) => {
        const time = new Date().getTime();
        dispatch({
            type: FRAME_FINISH,
            frameId: frameId,
            time: time
        })
    };
};

export const reset = () => {
    localStorage.clear();
    localStorage.setItem("seconds", "00");
    localStorage.setItem("minutes", "00");
    return (dispatch) => {
        dispatch({
            type: RESET,
        })
    }
};

export const clueUse = (gameId, riddleId, clueId) => {
    return async (dispatch, getState) => {
        const time = new Date().getTime();
        const journey = getState().journey;
        const game_session = journey[`game_${gameId}`].game_session;
        const riddle_session = journey[`game_${gameId}`][`riddle_${riddleId}_session`];

        sendEventToServer({
            game_session: game_session,
            game: gameId,
            riddle_session: riddle_session,
            riddle: riddleId,
            status: `use_clue_${clueId}`,
            time: time,
            token: getState().journey.user_token
        });

        dispatch({
            type: CLUE_USE,
            gameId: gameId,
            riddleId: riddleId,
            clueId: clueId,
            time: time
        })
    }
}