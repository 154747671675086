import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";
import {AudioListItem} from "./audio-list-item";
import "./audio-list.scss";
import LIST_PAPER from "./images/list_paper.png";
import {MdMusicNote} from "react-icons/all";
import {AudioListBottom} from "./audio-list-bottom";

export const ToolAudioList = (
    {
        items,
        screenBackground,
        listBackgroundColor,
        audio_list_background_image,
        iTunes,
        textColor,
        borderColor,
        borderWidth,
        iconsSize,
        className,
        list_view = "blocks",
        handleFrameFinish,
    }) => {
    const [activeItem, setActiveItem] = useState(null);

    const itemStyle = {borderColor, textColor, borderWidth, iconsSize};

    useEffect(() => {
        handleFrameFinish();
    }, []);

    const renderContacts = items.map((item, i) => {
        console.log(item);
        return <AudioListItem {...item}
                              isActive={activeItem === i}
                              toggleAudio={() => setActiveItem(activeItem === i ? null : i)}
                              key={i}
                              index={i}
                              list_view={list_view}
                              textColor={textColor}
                              itemStyle={itemStyle}/>
    });


    return (
        <ContactListWrapper className={classNames("audio-list", {iTunes}, `${className}`)}
                            backgroundImage={audio_list_background_image}
                            backgroundColor={screenBackground}>
            <Contacts className="audio-list-contacts">
                <div className={`audio-list-list view-${list_view}`}>{renderContacts}</div>
            </Contacts>
            {iTunes && <AudioListBottom item={items[activeItem]}/>}
        </ContactListWrapper>
    )
};

const Contacts = styled.div`
    padding: 10px; 
   `;
const ContactListWrapper = styled.div`
    ${props => `
        ${props.backgroundImage && `background-image: url(${props.backgroundImage})`};
        
    `}

`;
const Letter = styled.div`
    color: ${props => props.color};
`;

ToolAudioList.defaultProps = {
    items: [],
    className: ""
};
ToolAudioList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
                name: PropTypes.string,
                icon: PropTypes.string,
                color: PropTypes.string,
                audio: PropTypes.string,
                video: PropTypes.string,
                placeholder: PropTypes.string,
                phone_number: PropTypes.string,
            }
        )),
    className: PropTypes.string
}