import styled from "styled-components";
import {slideScreen} from "./slide";

const fade = `
    .fade-enter {
        opacity: 0.01;
    }
    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }
    .fade-exit {
        opacity: 1;
    } 
    .fade-exit.fade-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }
`;



const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    overflow: auto;  
    background-color: #d3d2d8;
    // margin-bottom: 100px;
    ${slideScreen};
    ${fade}; 
  
    div.transition-group { 
        position: relative;
    }
    section.route-section {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    
    &::-webkit-scrollbar-track {
        display: none;
    }
    
    &::-webkit-scrollbar-thumb {
        display: none;
    }
`;

export default  Wrapper;