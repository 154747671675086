import React, {useEffect, useState} from 'react';
import {HBox} from 'react-stylesheet';
import {Container} from 'react-grid-system';
import Iframe from '../../01_atoms/iframe';


const ToolVideo = ({videoUrl, handleFrameFinish}) => {
    const [videoEnded, setVideoEnded] = useState(false);

    useEffect(() => {
        handleFrameFinish();
    }, []);

    useEffect(() => {
        let video = document.getElementById("tool-video");
        if (video) {
            video.onended = () => setVideoEnded(true);
        }
    }, []);

    const videoStyle = {
        width: '100%',
        maxHeight: "100vh",
        position: videoEnded ? "static" : "fixed",
        top: 0,
        bottom:0,
        left:0,
        right: 0
    };
    return (
        <HBox background={'#000'} alignItems={'center'} style={{flex: 1, width: "500px", maxWidth:"100%"}}>
            <Container className={'d-flex flex-wrap align-items-center justify-content-center pt-0'} style={{width: '100%'}}>
                <video id='tool-video' autoPlay controls style={videoStyle}>
                    <source type="video/mp4"
                            src={videoUrl}/>
                </video>
            </Container>
        </HBox>
    )

}
export default ToolVideo;

