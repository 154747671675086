import React, {useState, useEffect, useReducer, useContext} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {ACTIVE_SECTION, SHOW_FEEDBACK, USER_ANSWERS} from "./trivia-local-storage-consts";
import {TriviaSection} from "./trivia-section";
import {TriviaIndicators} from "./trivia-indicators";
import {TriviaResults} from "./trivia-results";
import {TriviaButtons} from "./trivia-buttons";
import "./trivia-style.scss";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import {Container} from "react-grid-system";


const defaultUserAnswers = (items) => items.map((item, index) => {
    return {a: item.answer, u: -1, key: index}
});

const answersReducer = (state, action) => {
    if (action.type === "reset") {
        return defaultUserAnswers(action.items)
    } else {
        const index = action.questionIndex;
        state[index].u = action.answerIndex;
        localStorage.setItem(USER_ANSWERS, JSON.stringify(state));
        return state;
    }

};

export const TriviaContext = React.createContext({
    activeSection: 0,
    userAnswers: [],
    setUserAnswers: answersReducer,
    allCorrect: false,
    finalScreenActive: false,
    isResolve: false
});

export const ToolTrivia = (
    {
        items,
        className,
        correctTitle = "כל הכבוד",
        correctSubTitle = "כעת תוכלו לבחור במועמד שלדעתכם הכי ראוי לקבל את הפרס",
        correctDescription,
        wrongTitle,
        wrongSubTitle,
        wrongDescription,
        finalImageUrl,
        nextButtonBackground,
        nextButtonColor,
        prevButtonBackground,
        prevButtonColor,
        nextFrameButton
    }) => {
    const localUserAnswers = JSON.parse(localStorage.getItem(USER_ANSWERS));
    const frameContext = useContext(FrameContext);
    const isResolve = frameContext.isFrameFinish;

    const [activeSection, setActiveSection] = useState(JSON.parse(localStorage.getItem(ACTIVE_SECTION)) || 0);
    const [showFeedback, setShowFeedback] = useState(JSON.parse(localStorage.getItem(SHOW_FEEDBACK)));

    const [userAnswers, setUserAnswers] = useReducer(answersReducer, localUserAnswers || defaultUserAnswers(items));
    const allCorrect = userAnswers.every(item => item.u === item.a);

    const finalScreenActive = activeSection >= items.length;

    useEffect(() => {
        localStorage.setItem(SHOW_FEEDBACK, JSON.stringify(showFeedback));
    }, [showFeedback])

    useEffect(() => {
        localStorage.setItem(ACTIVE_SECTION, JSON.stringify(activeSection))
        if (finalScreenActive) {
            setShowFeedback(true);
        }
    }, [activeSection, finalScreenActive]);

    const showContinueButton = !finalScreenActive || !isResolve;

    return (
        <TriviaContext.Provider value={{userAnswers, setUserAnswers, activeSection, allCorrect, finalScreenActive, isResolve}}>
            <div className={classNames("trivia", `${className}`)}>
                {items.length === 0 ?
                    <p>לא נמצאו שאלות</p> :
                    finalScreenActive ?
                        <TriviaResults title={allCorrect ? correctTitle : wrongTitle}
                                       subtitle={allCorrect ? correctSubTitle : wrongSubTitle}
                                       description={allCorrect ? correctDescription : wrongDescription}
                                       finalImageUrl={finalImageUrl}/>
                        :
                        items.map((item, index) => {
                            console.log(item)
                            return <TriviaSection {...item}
                                                  questionIndex={index}
                                                  isActive={activeSection === index}
                                                  key={index}/>
                        })
                }

                <TriviaIndicators items={items}
                                  showFeedback={showFeedback}
                                  onClick={(index) => setActiveSection(index)}/>


                <TriviaButtons setActiveSection={setActiveSection}
                               showContinueButton={showContinueButton}
                               nextButtonBackground={nextButtonBackground}
                               nextButtonColor={nextButtonColor}
                               prevButtonBackground={prevButtonBackground}
                               prevButtonColor={prevButtonColor}/>

                {/*{finalScreenActive && nextFrameButton && nextFrameButton}*/}

            </div>
        </TriviaContext.Provider>
    )
};

ToolTrivia.defaultProps = {
    items: [],
    className: "",
    correctTitle: "כל הכבוד!",
    correctSubTitle: "עניתם נכון על כל השאלות",
    correctDescription: "",
    wrongTitle: "כמעט",
    wrongSubTitle: "בדקו שוב את כל השאלות",
    wrongDescription: "",
    handleToolFinish: () => console.log("Tool Finish"),
    nextButtonBackground: "#004A8B",
    nextButtonColor: "#fff",
    prevButtonBackground: "#8b0000",
    prevButtonColor: "#fff",
};

ToolTrivia.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            questionLabel: PropTypes.string,
            question: PropTypes.string,
            questionImageUrl: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string),
            answer: PropTypes.number
        })
    ).isRequired,
    className: PropTypes.string,
    correctTitle: PropTypes.string,
    correctSubTitle: PropTypes.string,
    correctDescription: PropTypes.string,
    wrongTitle: PropTypes.string,
    wrongSubTitle: PropTypes.string,
    wrongDescription: PropTypes.string,
    handleToolFinish: PropTypes.func,
    finalImageUrl: PropTypes.string,
    nextButtonBackground: PropTypes.string,
    nextButtonColor: PropTypes.string,
    prevButtonBackground: PropTypes.string,
    prevButtonColor: PropTypes.string,
};