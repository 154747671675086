import React, {useEffect} from 'react';
import DeviceOrientation, {Orientation} from 'react-screen-orientation'
import {Element} from 'react-stylesheet';
import {MdPhoneIphone} from 'react-icons/md';
import styled, {keyframes} from "styled-components";
import {colors, gutters, font_size} from '../../00_base/variables';
import {VBox} from 'react-stylesheet/lib/Box';
import TifzoretBoard from "./tifzoret-board";
import {MobileHidden, MobileOnly} from "../../01_atoms/responsive/responsive";

const ToolTifzoret = ({nextFrameButton, correctImages, wrongImages, number, squares_language, show_numbers = true, handleFrameFinish}) => {

    useEffect(() => {
        handleFrameFinish();
    }, []);

    return (
        <Element style={{width: "100%"}}>
            <VBox width={'100%'}>
                <MobileHidden>
                    <Element style={{paddingRight: gutters.md}}>
                        {correctImages && correctImages.length > 0 && wrongImages && wrongImages.length > 0 &&
                        <TifzoretBoard hideNumberCode={true}
                                       correctImages={correctImages}
                                       wrongImages={wrongImages}
                                       number_code={number}
                                       squares_language={squares_language}
                                       show_numbers={show_numbers}/>
                        }
                    </Element>
                </MobileHidden>
                <MobileOnly>
                    <DeviceOrientation lockOrientation={'landscape'}>
                        <Orientation orientation='landscape' alwaysRender={false}>
                            <Element style={{paddingRight: gutters.md}}>
                                {correctImages && correctImages.length > 0 && wrongImages && wrongImages.length > 0 &&
                                <TifzoretBoard hideNumberCode={true}
                                               correctImages={correctImages}
                                               wrongImages={wrongImages}
                                               number_code={number}/>
                                }
                            </Element>
                        </Orientation>
                        <Orientation orientation='portrait' alwaysRender={false}>
                            <div style={{textAlign: 'center', paddingTop: '100px'}}>
                                <p style={{
                                    color: '#fff',
                                    textAlign: 'center',
                                    fontSize: font_size.lg
                                }}>
                                    יש לסובב את המכשיר
                                </p>
                                <PhoneIconWrapper>
                                    <MdPhoneIphone color={'#fff'}
                                                   size={90}
                                                   style={{
                                                       marginTop: gutters.sm,
                                                       position: 'relative',
                                                   }}/>
                                </PhoneIconWrapper>
                            </div>
                        </Orientation>
                    </DeviceOrientation>
                </MobileOnly>
                {/*{nextFrameButton && nextFrameButton}*/}

            </VBox>
        </Element>
    )
}


const Rotate = keyframes` 
        0% {
            transform: rotate(0deg); 
        }
        40% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0deg); 
        }
        100% {
            transform: rotate(0deg); 
        }
`;

const PhoneIconWrapper = styled.div`
    transform: rotate(90deg);
    transform-origin: center; 
    animation: ${Rotate} 3s infinite;
`;
export default ToolTifzoret;