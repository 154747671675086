class AdventureType {
    constructor(
        id,
        name,
        description,
        logo_url,
        game_subdomain,
        theme_id,
        guide_id,
        base_price,
        opening_video_url,
        final_video_url,
        storyboard_id,
        game_board,
        answers_file,
        is_active,
        show_in_site,
        description_short,
        description_homepage,
        description_inner,
        tags,
        adventuredata_id,
        adventuretemplate_id,
        age,
        players,
        duration,
        instructions_main,
        instructions_title,
        instructions_image,
        site_image_main,
        site_image_mobile,
        site_image_tablet,
        site_image_desktop,
        entrance_cta,
        entrance_color,
        entrance_background,
        entrance_image_desktop,
        entrance_image_mobile,
        lobby_title,
        lobby_subtitle,
        lobby_image_desktop,
        lobby_image_mobile,
        game_title,
        game_subtitle,
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.logo_url = logo_url;
        this.game_subdomain = game_subdomain;
        this.theme_id = theme_id;
        this.guide_id = guide_id;
        this.base_price = base_price || 300;
        this.opening_video_url = opening_video_url;
        this.final_video_url = final_video_url;
        this.storyboard_id = storyboard_id;
        this.game_board = game_board;
        this.answers_file = answers_file;
        this.is_active = is_active;
        this.show_in_site = show_in_site;
        this.description_short = description_short;
        this.description_homepage = description_homepage;
        this.description_inner = description_inner;
        this.tags = tags;
        this.adventuredata_id = adventuredata_id;
        this.adventuretemplate_id = adventuretemplate_id;
        this.age = age;
        this.players = players;
        this.duration = duration;
        this.instructions_main = instructions_main;
        this.instructions_title = instructions_title;
        this.instructions_image = instructions_image;
        this.site_image_main = site_image_main;
        this.site_image_mobile = site_image_mobile;
        this.site_image_tablet = site_image_tablet;
        this.site_image_desktop = site_image_desktop;
        this.entrance_cta = entrance_cta;
        this.entrance_color = entrance_color;
        this.entrance_background = entrance_background;
        this.entrance_image_desktop = entrance_image_desktop;
        this.entrance_image_mobile = entrance_image_mobile;
        this.lobby_title = lobby_title;
        this.lobby_subtitle = lobby_subtitle;
        this.lobby_image_desktop = lobby_image_desktop;
        this.lobby_image_mobile = lobby_image_mobile;
        this.game_title = game_title;
        this.game_subtitle = game_subtitle;
    }
};

export default AdventureType;