import React from 'react';
import './slider.scss';

import classNames from "classnames";
import {MdPlayArrow} from "react-icons/all";
import {StyledDiv} from "../../ui/css-general/css-styled";
import {indicatorCircle} from "../../tools/trivia copy/tool-trivia-styles";

const SliderView = (props) => {
    const {className, height, items, activeItem, nextItem, prevItem, noArrows, speed, indicatorStyle, debug} = props;

    return (
        <div className={classNames("slider", {className: className, ['slider-debug']: debug})}
             style={{maxWidth: "100%", height: height}}>
            <div className={'slider_wrapper'}>

                {/* {!noArrows && items.length > 1 && <div className={'slider_arrow slider_arrow-left'} onClick={prev}>
                    <Circle size={circleSize} background={circlesBackground ? circlesBackground : '#333333'}>
                        <MdChevronLeft size={`${circleArrowSize}px`} color={circlesColor ? circlesColor : '#ffffff'}/>
                    </Circle>
                </div>} */}

                <div className={'slider_content'}>
                    {items.map((item, i) => {
                        const itemClass =
                            activeItem === i ? ' slider_item-active' :
                                prevItem === i ? ' slider_item-prev' :
                                    nextItem === i && ' slider_item-next';

                        return (
                            <div key={i} className={`slider_item${itemClass ? itemClass : ''}`}
                                 style={{transitionDuration: `${speed}s` }}>
                                {item}
                            </div>
                        )
                    })}
                </div>

                <div className={'slider_indicators'}>
                    {!noArrows &&
                    <div className='slider_indicators-prev'
                         onClick={() => props.goToIndex(nextItem)}>
                        <MdPlayArrow color="#fff" size={30}/>
                    </div>
                    }
                    <div className="slider_indicators-container">
                        {items.length > 1 && items.map((item, i) => {
                            return (
                                <StyledDiv key={i}
                                           {...indicatorStyle}
                                           className={classNames("slider_indicators_item", {active: activeItem === i})}
                                           onClick={() => props.goToIndex(i)}>{i + 1}</StyledDiv>
                            )
                        })}
                    </div>
                    {!noArrows &&
                    <div className='slider_indicators-next'
                         onClick={() => props.goToIndex(prevItem)}>
                        <MdPlayArrow color="#fff" size={30}/>
                    </div>
                    }
                </div>

                {/* {!noArrows && items.length > 1 && <div className={'slider_arrow slider_arrow-right'} onClick={next}>
                    <Circle size={circleSize} background={circlesBackground ? circlesBackground : '#333333'}>
                        <MdChevronRight size={circleArrowSize} color={circlesColor ? circlesColor : '#ffffff'}/>
                    </Circle>
                </div>} */}

            </div>
        </div>
    )
};

export {SliderView};