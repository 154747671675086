import styled from 'styled-components';
import {colors, font_size, gutters} from "../../00_base/variables";

export const Wrapper = styled.div`
    background-color: ${props => props.backgroundColor};
 
`;
export const Question = styled.div`
    display: ${props => props.isActive ? 'block' : 'none'};
    max-width: 50rem;
    margin: 0 auto;
`;
export const IndicatorWrapper = styled.div`
    padding: 0;
    width: ${props => props.isFinalScreen ? "33.333%" : "auto"}
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
`;


export const OptionSquare = styled.div`
    padding: ${gutters.xs};
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2.5rem;
    border: 2px solid ${props => props.background ? props.background : colors.black};
    background: ${props => props.isActive ? props.background : colors.white};
    color: ${props => props.isActive ? props.color : colors.black};
    height: 100%;
    width: 100%;
    box-sizing: border-box !important;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s;
text-align: center;
    :hover {
        cursor: pointer;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }
`;


export const indicatorCircle = {
    fontSize: font_size.sm,
    fontWeight: 'bold',
    cursor: 'pointer'
};
export const questionTitle = {
    fontSize: font_size.lg,
    textAlign: 'center',
    marginBottom: 0
};
export const questionParagraph = {
    marginTop: 0,
    fontSize: font_size.md,
    textAlign: 'center',
    fontWeight: 'bold'
};

export const buttonsWrapper = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: gutters.xs
};
export const finalTitleStyle = {
    textAlign: 'right',
    color: colors.black
};
export const finalSubtitleStyle = {
    textAlign: 'center',
    fontSize: '2.2rem',
    margin: 0,
    fontWeight: 'normal',
    color: colors.black
};

export const finalDescriptionStyle = {
    textAlign: 'right',
    margin: 0,
    fontSize: '1.6rem',
    fontWeight: 'normal',
    color: colors.black
};