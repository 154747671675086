import React from "react";
import {Provider} from 'react-redux';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk'
import {BrowserRouter as Router} from "react-router-dom";
import {setConfiguration} from 'react-grid-system';
import {AppContent} from "./components/04_layout";
import {config, library} from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import {faBars, faUser, faTimes} from '@fortawesome/free-solid-svg-icons'
import {faTwitter, faFacebook} from '@fortawesome/free-brands-svg-icons'

import {
    gutterWidth,
    breakpoints,
    containerWidths,
    gridColumns,
} from './components/00_base/configurations';
import adventureReducer from "./store/reducers/adventure-reducers";
import journeyReducer from "./store/reducers/journey-reducer";

config.autoAddCss = false;
library.add(faBars, faUser, faTimes, faTwitter, faFacebook);

const rootReducer = combineReducers({
    gameData: adventureReducer,
    journey: journeyReducer
});

const persistedState = JSON.parse(localStorage.getItem('reduxState'))
    ? JSON.parse(localStorage.getItem('reduxState'))
    : {};
const store = createStore(rootReducer, persistedState,  applyMiddleware(ReduxThunk), );

store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()))
});
// **** NOTICE: Don't forget to update the variables in ./src/assets/css/variables. **** //

setConfiguration({
    defaultScreenClass: 'xl',
    breakpoints: breakpoints,
    containerWidths: containerWidths,
    gutterWidth: gutterWidth,
    gridColumns: gridColumns,
});

const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <div id={'app-wrapper'}>
                    <AppContent/>
                </div>
            </Router>
        </Provider>
    )
};

export default App;