import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Page} from '../../04_layout';
import {Image, Heading, Circle, Button, Span} from '../../01_atoms';
import {Link} from 'react-router-dom';
import {gutters, colors, font_size, font_family, viewport} from '../../00_base/variables';
import * as adventureActions from '../../../store/actions/adventure/adventure-actions';
import * as journeyActions from "../../../store/actions/journey/journey-actions";
import {Form} from '../../03_organisms';
import SimpleLoader from '../../01_atoms/loaders/simple-loader';
import {DICTIONARY} from "../../../dictionary/dictionary";
import styled from "styled-components";
import BACKGROUND_IMAGE from "../../../assets/images/homescreen-background.svg";


const passcodes = [
    {fake: "283774", real: "xh8sjz"}, // Shod Yahalomim
    {fake: "542337", real: "z8pwic"}, // Countdown
    {fake: "319927", real: "tiyv82"}, // Argaman Spider
    {fake: "182475", real: "eyzxs8"}, // Moamadim
    {fake: "449281", real: "dw4e47"}, // Man in Suite
    {fake: "328493", real: "le61nd"}, // Kad Kadosh
];

const ScreenHomepage = (props) => {
    const gameData = useSelector(state => state.gameData);
    const {adventure = {}, adventureData = {}, adventureType = {}} = gameData;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [counting, setCounting] = useState(null);
    const [passwordConfirmed, setPasswordConfirmed] = useState(false);
    const [videoEnded, setVideoEnded] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (counting === 0) {
            window.location.href = '/menu';
        } else if (counting >= 1) {
            setTimeout(() => setCounting(counting - 1), 1000);
        }
    }, [counting]);

    const passcodeSubmitHandler = (value) => {
        setIsLoading(true);
        setError(null);

        dispatch(journeyActions.reset());
        dispatch(adventureActions.clearApprovedPassword());

        const passcode = value.passcode;
        const passcodeRendered = passcodes.find(item => item.fake === passcode || item.real === passcode);

        if (passcodeRendered) {
            dispatch(adventureActions.getAdventureByPasscode(passcodeRendered.real)).then(setAdventure);
        } else {
            setError(true);
            setIsLoading(false);
        }

        //     const ASIF = value.passcode.toLowerCase() === "asif";
        // const BEGIN = value.passcode.toLowerCase() === "begin1";
        // const userPasscode = ASIF ? "v6dfpu" : BEGIN ? "2jvqud" : value.passcode.toLowerCase() === 'shirim' ? 'jb426h' : value.passcode.toLowerCase() === 'israel' ? '1swzte' : value.passcode;
        //
        // dispatch(adventureActions.getAdventureByPasscode(userPasscode.toLowerCase())).then(setAdventure);
    };

    useEffect(() => {
        let video = document.getElementById("homepage-video");
        if (video) {
            video.onended = () => setVideoEnded(true);
        }
    }, [passwordConfirmed]);

    const setAdventure = useCallback((adventureId) => {
        const setAdventureFrame = (adventureStore) => {
            dispatch(adventureActions.setAdventureFrames(adventureStore, () => {
                setPasswordConfirmed(true);
                setIsLoading(false);
            }))
        };

        if (adventureId) {
            dispatch(adventureActions.setSingleAdventure(adventureId, setAdventureFrame));
        } else {
            setError(true);
            setIsLoading(false);
        }

    }, [dispatch]);

    const icon = adventure && adventure.thumbnail;
    const language = (adventure && adventure.language) ? adventure.language : "Hebrew";


    const video = {
        width: '100%',
        maxHeight: "100vh",
        position: videoEnded ? "static" : "fixed",
        top: 0,
        bottom:0,
        left:0,
        right: 0
    };

    const opening_video = adventure?.json_data?.opening_video_url || "https://images.youdoadventures.com/1608121671459-opening_mp4.mp4";

    return (
        <Page {...props} style={{
            backgroundImage: `url(${passwordConfirmed ? adventureData?.welcome_screen_url_mobile : ""})`,
            backgroundSize: "contain",
            backgroundPosition: "bottom center",
            backgroundRepeat:"no-repeat"
        }}>
            <WrapperImage desktop={BACKGROUND_IMAGE}
                          mobile={BACKGROUND_IMAGE}
                          style={wrapper}>
                {!passwordConfirmed ?
                    <div style={passcodeFormWrapper}>
                        {adventureType && <Image src={adventureType.logo_url} width={180}/>}
                        <Heading tag={'h2'} style={title}>הזן מספר חקירה</Heading>
                        <Span style={{...validationMessage, opacity: !!error ? 1 : 0}}>
                            {error ? "מס' תיק חקירה שגוי" : `---`}
                        </Span>

                        <Form fields={[passcodeField]}
                              handleFormSubmit={passcodeSubmitHandler}
                              submitStyle={submitStyle}
                              submitLabel={isLoading ? <SimpleLoader size={20} color={colors.white}/> : 'המשך'}/>

                    </div>
                    :
                    <div style={successWrapper}>
                        <Image src={adventureType.logo_url} width={50}/>
                        <Heading tag='h2'
                                 value={adventure.title} //adventure.adventure_name ||
                                 style={title}/>

                        {icon &&
                        <div style={iconWrapper}>
                            <Image width='200px' src={icon}/>
                        </div>
                        }

                        <div>
                            <video id='homepage-video' autoPlay controls style={video}>
                                <source type="video/mp4"
                                        src={
                                            opening_video
                                            // (adventureType && adventureType.opening_video_url) ||
                                            // (language === "Arabic" ?
                                            //         'https://api.youdoadventures.com/images/1597009618276-instructions_video_arabic_mp4.mp4' :
                                            //         'https://api.youdoadventures.com/images/1584977124801-instructions_mp4.mp4'
                                            // )
                                        }/>
                            </video>
                        </div>

                        {videoEnded &&
                        <Button block style={startButton}>
                            <Link to={`/menu/${adventure.adventuredata_id}`} style={startLink}>
                                {DICTIONARY.are_you_ready[language]}
                            </Link>
                        </Button>
                        }

                        {!!counting &&
                        <Circle style={countingCircle} size={200}>
                            <Span value={counting} style={countingStyle}/>
                        </Circle>
                        }
                    </div>
                }

            </WrapperImage>
        </Page>
    )
};

const WrapperImage = styled.div`
    ${props => `
        ${(props.mobile || props.desktop) && `background-image: url(${props.mobile || props.desktop})`};
            ${props.desktop &&
`@media(min-width: ${viewport.lg}) {
                    background-image: url(${props.desktop});
                }`
}`
}
`;

const passcodeField = {
    type: 'text',
    name: 'passcode',
    color: colors.black,
    inputStyle: {
        backgroundColor: "#4d4d4d",
        fontSize: font_size.xxxl,
        fontFamily: font_family.medium,
        textAlign: 'center',
        textTransform: "lowercase",
        border: "4px solid #fff",
        borderRadius: "4px",
        height: "30px",
        color: "#fff"
    },
    fontWeight: 'bold',

};

const wrapper = {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    width: '100%',
    padding: `30px ${gutters.xl}`,
    margin: '0 auto',
    backgroundPosition: 'top center',
    backgroundSize: "80vh",
    backgroundRepeat: "no-repeat"
};
const logoWrapper = {
    position: 'relative',
    zIndex: 1,
    marginTop: gutters.md,
    textAlign: 'center'
};
const passcodeFormWrapper = {
    position: 'relative',
    zIndex: 1,
    width: '600px',
    maxWidth: '100%',
    margin: '0 auto',
    textAlign: 'center'
};
const countingCircle = {
    position: 'absolute',
    border: `3px solid ${colors.white}`,
    background: colors.black
};
const countingStyle = {
    fontSize: font_size.max,
    color: '#fff'
};
const startButton = {
    fontSize: font_size.lg,
    marginTop: '30px',
    color: colors.white,
    backgroundColor: colors.primary
};
const startLink = {
    display: "block",
    color: '#fff'
};
const title = {
    color: colors.white,
    textAlign: 'center',
    textShadow: '1px 1px 1px #000',
    marginBottom: gutters.xxs,
    marginTop: gutters.xxs,
    fontSize: "50px",
    lineHeight: "50px"
};

const iconWrapper = {
    marginBottom: gutters.lg
};
const successWrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '500px'
};
const validationMessage = {
    color: colors.white,
    textAlign: 'center',
    fontSize: font_size.sm
};
const submitStyle = {
    height: '4rem',
    fontSize: font_size.md
};

ScreenHomepage.default = {
    title: DICTIONARY.welcome["Hebrew"],
};
ScreenHomepage.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default ScreenHomepage;