import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from "styled-components";

// import ScreenTool from '../tool';
import ScreenLoading from '../loading/screen-loading';
import * as journeyActions from '../../../store/actions/journey/journey-actions';
import {updateLocalStorage} from "../../../store/actions/adventure/adventure-actions";
import "./screen-riddle.scss";
import classNames from "classnames";
import {ScreenTool} from "../tool/screen-tool";
import {Heading} from "../../01_atoms";

export const RiddleContext = React.createContext({
    updateGameLevel: null,
    handleRiddleFinish: null,
    savePlayerData: null,
    playerData: null,
    adventureName: "",
});

const ScreenRiddle = ({match, history}) => {
    const gameData = useSelector(state => state.gameData);
    const journey = useSelector(state => state.journey);
    const gameLevel = localStorage.getItem("game-level");
    const riddleId = match.params.id;
    const frameIndex = match.params.frameIndex || 1;
    const {riddles, adventure, frames} = gameData;
    const riddle = riddles && riddles.find(riddle => riddle.id === parseInt(riddleId));
    const riddleIndex = riddles && riddles.indexOf(riddle);
    const adventureName = adventure && adventure.adventure_name;
    const userDataName = `riddle-${riddleIndex}-user-data`;
    const playerData = localStorage.getItem(userDataName);

    const [frameLevel, setFrameLevel] = useState(frameIndex - 1);

    const dispatch = useDispatch();
    const isToolFullscreen = history.location?.hash === "#ring-ring";

    useEffect(() => {
        if (riddles && riddleIndex < 0) {
            window.location.href = "/";
        }
    }, [riddles]);

    useEffect(() => {
        if (frameLevel + 1 !== parseInt(frameIndex)) {
            history.push(`/riddle/${riddleId}/${frameLevel + 1}`)
        }
    }, [frameLevel]);

    const {riddlestemplates, style = {}, templatedata = {}} = riddle;

    const savePlayerData = (data) => updateLocalStorage(userDataName, JSON.stringify(data));
    const updateGameLevel = () => updateLocalStorage("game-level", riddleIndex + 1);
    const handleRiddleFinish = () => dispatch(journeyActions.riddleFinish(riddleId));
    const gotoNextFrame = () => setFrameLevel(frameLevel + 1);
    const isLastFrame = parseInt(frameIndex) === riddlestemplates.length;

    const handleFrameContinue = () => {
        if (isLastFrame) {
            const {handleRiddleSolve} = riddle;
            if (handleRiddleSolve) {
                handleRiddleSolve();
            }
            history.push("/menu");
        } else {
            gotoNextFrame();
        }
    };

    if (!riddle) {
        return <ScreenLoading/>
    }

    if (riddleIndex > gameLevel) {
        // history.push("/menu");
    }

    const riddleContext = {updateGameLevel, savePlayerData, handleRiddleFinish, playerData, adventureName};

    const frame = frames.find(frame => frame.id === riddlestemplates[frameLevel]);
    // const frameFinish = journey[`frame_${frame.id}_finish`];

    const riddleClassName = classNames(`screen-riddle screen-riddle-${riddleId} `, {fullscreen: isToolFullscreen});


    return (
        <RiddleContext.Provider value={riddleContext}>
            <RiddleWrapper id='screen-riddle' className={riddleClassName}
                           style={style} {...templatedata}>



                {riddle.title && <div className={"riddle-header"}>
                    <h3>{riddle.title}</h3>
                </div>
                }


                <ScreenTool key={frame.id}
                            riddleData={riddle}
                            toolId={frame.categories[0]}
                            toolData={frame}
                            isFullScreen={isToolFullscreen}
                            riddleIndex={riddleIndex}
                            riddles={riddles}
                            handleFrameContinue={handleFrameContinue}
                            isLastFrame={isLastFrame}/>
            </RiddleWrapper>
        </RiddleContext.Provider>
    )
};

const RiddleWrapper = styled.div`
    ${props => `
        ${props.backgroundImage ? `background-image: url(${props.backgroundImage})` : ""};
        ${props.backgroundColor ? `background-color: ${props.backgroundColor}` : ""};
    `}
`;

export default ScreenRiddle;