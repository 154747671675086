import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FrameContext} from "../screen-tool";
import {Label, Button} from "../../../01_atoms";
import "./tool-password.scss";

export const ToolPassword = ({password, successHandler, label}) => {
    const isFrameFinish = useContext(FrameContext).isFrameFinish;
    const [success, setSuccess] = useState(isFrameFinish);
    const [value, setValue] = useState(isFrameFinish ? password : "");
    const [error, serError] = useState(false);

    const onSubmit = () => {
        if (value.toLowerCase() === password.toLowerCase()) {
            setSuccess(true);
            successHandler();
        } else {
            serError(true);
        }
    };

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                serError(false);
                setValue("");
            }, 2345);
        }
    }, [error]);

    return (
        <div className="tool-password-field">

            {label && <Label value={label} className="tool-password-label"/>}

            <div className="tool-password-input">
                <input onChange={e => setValue(e.target.value)}
                       className="tool-password-input"
                       value={value}
                       placeholder="הכנס סיסמא"
                       disabled={success}
                       type="text"/>
            </div>

            {error ? <p style={{fontSize:"18px", color:"red", fontWeight:"bold", textAlign:"center", marginTop:0}}>סיסמא שגוייה. נסו שנית.</p> : !success && <Button style={{width:"110px",margin:"0 auto", display:"block"}} onClick={onSubmit}>שלח</Button>}

        </div>
    )
};

ToolPassword.propTypes = {
    password: PropTypes.string.isRequired,
    successHandler: PropTypes.func.isRequired
}