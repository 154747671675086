import {fetchData} from "./fetch-data";
import {
    adventureDataObject,
    adventureObject,
    adventureTypeObject,
    riddlesObject
} from "./adventure-structure-objects";
import * as journeyActions from "../journey/journey-actions";
 import ToolContactList from "../../../components/tools/contact-list";
import ToolDefiner from "../../../components/tools/definer";
import ToolGallery from "../../../components/tools/gallery";
import ToolImage from "../../../components/tools/image";
import ToolVideo from "../../../components/tools/viedo";
import ToolTrivia from "../../../components/tools/trivia";
import ToolTruthOrLie from "../../../components/tools/lie-truth/tool-truth-lie";
import ToolTifzoret from "../../../components/tools/tifzoret";
import ToolTranslator from "../../../components/tools/translator";
import ToolTreasure from "../../../components/tools/treasure";
import ToolPuzzle from "../../../components/tools/puzzle";

import Template from "../../models/template";
import {ToolSlider} from "../../../components/tools/slider/tool-slider";
import {ToolContent} from "../../../components/tools/content/tool-content";
import {ToolCuttingWires} from "../../../components/tools/cutting-wires/tool-cutting-wires";
import {ToolAudio} from "../../../components/tools/audio/tool-audio";
import {ToolAudioList} from "../../../components/tools/audio-list/tool-audio-list";
import {ToolCoder} from "../../../components/tools/coder/tool-coder";
import {ToolRotaries} from "../../../components/tools/rotaries/tool-rotaries";
import {ToolChooseItem} from "../../../components/tools/choose-item/tool-choose-item";

export const SET_ADVENTURES = 'SET_ADVENTURES';
export const SET_SINGLE_ADVENTURE = 'SET_SINGLE_ADVENTURE';
export const SET_ADVENTURE_FRAMES = 'SET_ADVENTURE_FRAMES';
export const CREATE_ADVENTURE = 'CREATE_ADVENTURE';
export const UPDATE_ADVENTURE = 'UPDATE_ADVENTURE';
export const UPDATE_FRAMES = 'UPDATE_FRAMES';
export const DELETE_ADVENTURE = 'DELETE_ADVENTURE';
export const SET_SINGLE_ADVENTURETYPE = 'SET_ADVENTURES';
export const CLEAR_APPROVED_PASSWORD = 'CLEAR_APPROVED_PASSWORD';
    export const PASSWORD_APPROVED = 'PASSWORD_APPROVED';
    export const SET_ADVENTURE = 'SET_ADVENTURE';
export const UPDATE_RIDDLE = "UPDATE_RIDDLE";
export const UPDATE_FRAME = "UPDATE_FRAME";


const api = 'https://api.youdoadventures.com';  // API STG
const adventure_passcode_url = `${api}/adventures-by-passcode`;
// const adventure_url = `${api}/adventures`;
// const adventureData_url = `${api}/adventureData`;
// const adventureType_url = `${api}/adventureType`;
// const riddles_url = `${api}/riddles`;
// const tools_url = `${api}/riddlesTemplate`;

const tools = [
    { id: 47, component: ToolContactList },
    { id: 48, component: ToolDefiner },
    { id: 56, component: ToolGallery },
    { id: 7, component: ToolImage },
    { id: 57, component: ToolVideo },
    { id: 49, component: ToolTrivia },
    { id: 35, component: ToolTruthOrLie },
    { id: 296, component: ToolTrivia },
    { id: 9, component: ToolTifzoret },
    { id: 46, component: ToolTifzoret },
    { id: 15, component: ToolTranslator },
    { id: 60, component: ToolTreasure },
    { id: 38, component: ToolPuzzle},
    { id: 65, component: ToolSlider},
    { id: 62, component: ToolContent},
    { id: 63, component: ToolCoder},
    { id: 64, component: ToolRotaries},
    { id: 73, component: ToolCuttingWires},
    { id: 75, component: ToolAudio},
    { id: 76, component: ToolAudioList},
    { id: 78, component: ToolChooseItem}
];

export const getAdventureByPasscode = passcode => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${adventure_passcode_url}/?passcode=${passcode}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const data = await response.json();
            if (data.id) {
                updateLocalStorage('adventureId', data.id);
                updateLocalStorage('game-level', "0");
                updateLocalStorage('approved-passwords', "[]");
                for (let i = 0; i < 10; i++) {
                    updateLocalStorage( `riddle-${i}-user-data`, null);
                }
                return data.id
            }

        } catch (error) {
            throw error
        }
    }
};

export const setSingleAdventure = (adventureId, callback) => {
    return async (dispatch) => {
        // await fetch(`getAdvenureWithDataAndRiddles/${adventureId}`)
        await fetchData(
            "get",
            `getAdvenureWithDataAndRiddles/${adventureId}`,
            "",
            response => {

                if (response.adventureType) {
                    callback(response);
                } else {
                    fetchData(
                        `get`,
                        `adventureType/239`, //${response.adventure.adventuretype || response.adventureData.adventure_type}
                        {},
                        adventureType => {
                            callback({...response, adventureType: adventureTypeObject(adventureType)})
                        }
                    )
                }


                // dispatch({
                //     type: SET_SINGLE_ADVENTURE,
                //     adventure: adventureObject(response.adventure),
                //     adventureData: adventureDataObject(response.adventureData),
                //     riddles: riddlesObject(response.adventureData.riddles.map(riddleId => response.riddles.find(riddle => riddleId === riddle.id))),
                //     adventureType: adventureTypeObject(response.adventureType),
                // });
            }
        )
    }
};

export const setAdventureFrames = (adventureStore, callback) => {
    return async (dispatch, getState) => {
        const {adventure, adventureData, adventureType, riddles} = adventureStore;
        const getFrameData = (frameId) => new Promise(resolve => {
            dispatch(getSingleTemplate(frameId, frame => resolve(frame)))
        });
        // const allFramesId = adventureStore.riddles.map(riddle => riddle.riddlestemplates).flat();
        let allFramesId = [];
        for (let i = 0; i < adventureData.riddles.length; i++) {
            const riddleId = adventureData.riddles[i];
            const riddle = riddles.find(riddle => riddle.id === riddleId);
            dispatch(journeyActions.setAdventuresRiddle(riddleId));
            allFramesId.push(riddle.riddlestemplates);
        }


        await Promise.all(allFramesId.flat().map(getFrameData)).then(
            frames => {
                dispatch({
                    type: SET_SINGLE_ADVENTURE,
                    adventure: adventureObject(adventure),
                    adventureData: adventureDataObject(adventureData),
                    riddles: riddlesObject(adventureData.riddles.map(riddleId => riddles.find(riddle => riddleId === riddle.id))),
                    adventureType: adventureTypeObject(adventureType),
                    frames: frames.map(frame => {
                        const toolComponent = tools.find(tool => frame.categories.some(cat => cat === tool.id))
                        return {...frame, component: toolComponent}
                    })
                })
                callback(frames)
            })
    }
};

export const setSingleAdventureType = (adventureTypeId) => {
    return async (dispatch, getState) => {
        await fetchData(
            `get`,
            `adventureType/${adventureTypeId}`,
            {},
            response => {
                const adventureTypeData = adventureTypeObject(response);
                dispatch({
                    type: SET_SINGLE_ADVENTURETYPE,
                    adventureType: adventureTypeData
                })
                return adventureTypeData;
            }
        )
    }
};
export const getSingleTemplate = (templateId, callback) => {
    return async (dispatch, getState) => {
        await fetchData(
            "get",
            `riddlesTemplate/${templateId}`,
            {},
            templateData => {
                if (callback) {
                    callback(templateData);
                }
                return new Template(
                    templateData.id,
                    templateData.title,
                    templateData.description,
                    templateData.categories,
                    templateData.categorydata,
                    templateData.description_short,
                    templateData.printimageurl,
                    templateData.riddle_name,
                    templateData.show_in_store,
                    templateData.thumbnail,
                    templateData.user_email,
                    templateData.videourl,
                    templateData.is_published,
                    templateData.tags,
                    templateData.created_at,
                    templateData.clues,
                    templateData.instructions,
                    templateData.editable,
                    templateData.password

                )
            }
        )
    }
};
export const approvePassword = (index) => {
    return (dispatch) => {
        dispatch({
            type: PASSWORD_APPROVED,
            index: index
        })
    }
};
export const clearApprovedPassword = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_APPROVED_PASSWORD,
        })
    }
}

export const updateLocalStorage = (name, value) => {
    localStorage.setItem(name, value);
};