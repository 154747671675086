import React, {useContext, useEffect, useState} from 'react';
import _ from "lodash";
import classNames from "classnames";
import "./tool-maze.scss";
import tunnel_left01 from "../../../assets/images/maze/tunnel_left01.jpg";
import tunnel_right01 from "../../../assets/images/maze/tunnel_right01.jpg";
import tunnel_rightleft01 from "../../../assets/images/maze/tunnel_rightleft01.jpg";
import tunnel_rightleftfront01 from "../../../assets/images/maze/tunnel_rightleftfront01.jpg";
import tunnel_front01 from "../../../assets/images/maze/tunnel_front01.png";
import tunnel_frontleft01 from "../../../assets/images/maze/tunnel_frontleft01.png";
import tunnel_frontright01 from "../../../assets/images/maze/tunnel_frontright01.png";
import leftArrow from "../../../assets/images/maze/left-arrow.png";
import rightArrow from "../../../assets/images/maze/right-arrow.png";
import upArrow from "../../../assets/images/maze/up-arrow.png";
import {FrameContext} from "../../05_screens/tool/screen-tool";

export const ToolMaze = ({handleFrameFinish, handleFrameContinue}) => {
    const [currentTunnle, setCurrentTunnle] = useState(null)
    const [tunnelIndex, setTunnelIndex] = useState(null)
    const [stepsCount, setStepsCount] = useState(0)

    const frameContext = useContext(FrameContext);
    const isResolve = frameContext.isFrameFinish;


    useEffect(() => {
        if (isResolve) {
            handleFrameContinue();
        }
    }, [])

    useEffect(() => {
        setNewTunnelLocation()
      }, [])

    const setNewTunnelLocation = () => {
        if(stepsCount === 8){
            handleFrameFinish();
            handleFrameContinue();
        }
        setStepsCount(stepsCount + 1)
        setCurrentTunnle(getRandomLocation())
    }

    const getRandomLocation = () => {
        const mazeImages = [
            {src: tunnel_left01, left:true, right:false, forward:false},
            {src: tunnel_right01, left:false, right:true, forward:false},
            {src: tunnel_rightleft01, left:true, right:true, forward:false},
            {src: tunnel_rightleftfront01, left:true, right:true, forward:true},
            {src: tunnel_front01, left:false, right:false, forward:true},
            {src: tunnel_frontleft01, left:true, right:false, forward:true},
            {src: tunnel_frontright01, left:false, right:true, forward:true},
        ]

        let newIndex = _.random(0, mazeImages.length - 1)
        while(newIndex === tunnelIndex){
            newIndex = _.random(0, mazeImages.length - 1)
        }
        setTunnelIndex(newIndex)
        return mazeImages[newIndex]
    }

    const renderArrows = () => {
        const renderArrow = (direction, title, src) => {
            return(
                <>
                    <img className={classNames(`arrow ${direction}`, {disabled: !currentTunnle[direction]})}
                        onClick={()=> currentTunnle[direction] && setNewTunnelLocation()}
                        src={src}/>
                    <div className={`text ${direction}`}>{title}</div>
                </>
            )
        }

        return (
            <div className={"arrows-wrapper"}>
                {renderArrow("left", "שמאלה", leftArrow)}
                {renderArrow("right", "ימינה", rightArrow)}
                {renderArrow("forward", "קדימה", upArrow)}
            </div>
        )
    }

    return (
        <div className={"maze-container"}>
            {!_.isEmpty(currentTunnle) && 
                <>
                    <img id={tunnelIndex} className={"maze-image"} src={currentTunnle.src}/>
                    <div className="maze-arrows">
                    {renderArrows()}
                    </div>
                </>
            }
        </div>
    )
}