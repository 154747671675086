import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components'
import classNames from "classnames";
import "./numbers-coder.scss";
import {StyledButton} from "../../../ui/css-general/css-styled";

export const NumbersCoder = (
    {
        startNumber,
        endNumber,
        buttonsPerRow,
        buttonsBackgroundColor,
        buttonsTextColor,
        buttonsBorderRadius,
        buttonSize,
        buttonTextSize,
        onInputAdd,
        isButtons3D,
        type,
        buttonsColors,
        hideNumbers,
        dynamicStyle,
        colors
    }) => {

    const renderSingleNumber = (number, numberToSend = number, backgroundColor) => {
        const index = number === "#" ? 9 : number === 0 ? 10 : number === "✻" ? 11 : number - 1;
        return (
            <StyledButton className="coder-number"
                          key={index}
                          backgroundColor={type === "colors" ? colors[index] : "#44435e"}
                          // dynamicStyle}
                          // borderWidth={type === "colors" && "0"}
                          // textColor={buttonsTextColor}
                          // borderRadius={buttonsBorderRadius}
                          // size={buttonSize}
                          // textSize={buttonTextSize}
                          // backgroundColor={backgroundColor || buttonsBackgroundColor}
                          onClick={() => onInputAdd(numberToSend, backgroundColor || buttonsBackgroundColor)}>

                {type !== "colors" && number}
            </StyledButton>
        )

    }

    const renderNumbers = (startNumber, endNumber, buttonsPerRow) => {

        const returnValue = [];
        let buttonColorIndex = -1;

        for (let firstRowNumber = startNumber + 1; firstRowNumber <= endNumber; firstRowNumber += buttonsPerRow) {
            const isLast = buttonColorIndex === buttonsColors.length - 1;
            const rowKeys = Array.from(Array(buttonsPerRow).keys());
            const renderNumber = (rowIndex) => {
                 buttonColorIndex = isLast ? 0 : buttonColorIndex + 1;
                const number = rowIndex + firstRowNumber;
                const numberToSend = rowIndex + firstRowNumber;
                const buttonColor = buttonsColors[buttonColorIndex];
                return renderSingleNumber(number, numberToSend, buttonColor, firstRowNumber + rowIndex);
            }
            const numbersRow = <div className="coder-numbers-row" >{rowKeys.map(renderNumber)}</div>;
            returnValue.push(numbersRow);
        }

        returnValue.push(
            <div className={"coder-numbers-row"}>
                {
                    [
                        {title: "#", value: "#"},
                        {title: startNumber, value: startNumber},
                        {title: "✻", value: "*"}
                    ].map(({title, value}) => {

                        if (buttonColorIndex === buttonsColors.length - 1) {
                            buttonColorIndex = 0;
                        } else {
                            buttonColorIndex++;
                        }
                        return renderSingleNumber(title, value, buttonsColors[buttonColorIndex])

                    })
                }
            </div>
        )
        return returnValue
    }

    return (renderNumbers(startNumber, endNumber, buttonsPerRow))
}

const SingleButton = styled.div`
    ${({textColor, backgroundColor, borderRadius, size, textSize}) => `
        color: ${textColor};
        background-color: ${backgroundColor};
        font-size: ${textSize}px;
        height: ${size}px;
        width: ${size}px;
        border-radius: ${borderRadius}%;
    `}
`;

NumbersCoder.defaultProps = {
    startNumber: 0,
    endNumber: 9,
    buttonsPerRow: 3,
    buttonsBackgroundColor: "white",
    buttonsTextColor: "black",
    buttonsBorderRadius: 50,
    buttonSize: 50,
    buttonTextSize: 25,
    isButtons3D: true,
};

NumbersCoder.propTypes = {
    startNumber: PropTypes.number,
    endNumber: PropTypes.number,
    buttonsPerRow: PropTypes.number,
    buttonsBackgroundColor: PropTypes.string,
    buttonsTextColor: PropTypes.string,
    buttonsBorderRadius: PropTypes.number,
    buttonSize: PropTypes.number,
    buttonTextSize: PropTypes.number,
    isButtons3D: PropTypes.bool,
    hideNumbers: PropTypes.bool,
    buttonsColors: PropTypes.array,
};