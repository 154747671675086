import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {phoneNumbers} from "./phone-numbers";
import {MdPerson} from "react-icons/md";

export const ContactListItem = (
    {
        name,
        icon,
        color,
        phone_number,
        index,
        itemStyle,
        showScreenCall
    }) => {
    const {iconsSize = 40, borderWidth, borderColor} = itemStyle;
    return (
        <ContactListItemWrapper borderWidth={borderWidth}
                                borderColor={borderColor} className={`contact-list-item`}>
            <Thumbnail className={"contact-list-item-thumbnail"} size={iconsSize} color='#000'
                       >
                {icon ? <img src={icon} alt={`${name}-icon`} width="100%"/> : <MdPerson color={'#fff'} size={23}/>}
            </Thumbnail>

            <div className="contact-list-item-container">
                <div className="contact-list-item-link" onClick={showScreenCall}>
                    <div className="contact-list-item-details">
                        <div>
                            <Name className="contact-list-item-name" color={color}>{name}</Name>
                        </div>

                    </div>
                </div>
            </div>
        </ContactListItemWrapper>
    );
};

const ContactListItemWrapper = styled.div`
${props => `
        border-bottom: ${props.borderWidth}px solid ${props.borderColor};
    `}
`;

const Thumbnail = styled.div`
    ${props => `
        width: ${props.size}px;
        height: ${props.size}px;
        background: ${props.background || 'transparent'};
    `} 
`;
const Name = styled.span`
     ${props => `
         color: ${props.color};
    `};
`;
const PhoneNumber = styled.span`
     ${props => `
         color: ${props.color};
    `};
`;

ContactListItem.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    placeholder: PropTypes.string,
    phone_number: PropTypes.string,
    index: PropTypes.number,
    itemStyle: PropTypes.object,
}