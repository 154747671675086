import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Video from "../../01_atoms/video";
import {Button, Image, Paragraph} from "../../01_atoms";
import "./tool-content.scss";
import {font_size} from "../../00_base/variables";
import FieldText from "../../02_molecules/form-fields/text";


export const ToolContent = (
    {
        toolData,
        items = [],
        handleFrameFinish,
        elementBackgroundColor,
        elementBorderColor = "#000",
        handleFrameContinue,
        elementBorderSize = 0
    }) => {

    const [userEmail, setUserEmail] = useState("");

    useEffect(() => {
        if (!toolData.categorydata.innerPassword) {
            handleFrameFinish();
        }
    }, []);

    const renderItems = items.map((item, index) => {
        const {type, value, imageSize = "100%"} = item;
        const className = `tool-content-${type}`;
        let element;
        switch (type) {
            case "text":
                element = <p className={className} dangerouslySetInnerHTML={{__html: value}}/>;
                break;
            case "image":
                element = <Image className={className} src={value} style={{margin: "0 auto"}} block width={imageSize}/>;
                break;
            case "video":
                element = <Video className={className} source={value}/>;
                break;
            default:
                element = <div/>;
        }
        return <ToolContentItem className="tool-content-item" maxHeight={item.maxHeight}
                                key={index}>{element}</ToolContentItem>;
    });

     return (
        <ToolContentWrapper className="tool-content"
                            backgroundColor={elementBackgroundColor}
                            borderColor={elementBorderColor}
                            borderSize={elementBorderSize}>

            {renderItems}

            {toolData.id === 3885 &&
            <div className="email-wrapper">
                <input onChange={(e) =>  setUserEmail(e.target.value)}
                placeholder={"הכנס כתובת אימייל"}/>
                {/*<FieldText changeHandler={(name, value) => console.log(value)}*/}
                {/*           keydownHandler={(e) =>  console.log(e.target.value)}*/}
                {/*           placeholder={"לקבלת קוד ההטבה לשליחה לחבר הזינו כאן כתובת מייל"}*/}
                {/*           name={"email"}/>*/}

                <Button value={"לקבלת ההטבה"}
                        onClick={() => {
                            if (userEmail) {
                                handleFrameContinue();
                            } else {
                                alert("יש להזין כתובת אימייל על מנת לקבל את ההטבה")
                            }

                        }}
                        style={{marginBottom:"20px", backgroundColor:"#c01219", padding: "10px 30px", fontSize:"18px", color:"#fff", borderRadius: "20px"}}/>
                <Button value={"המשך ללא הטבה"}
                        onClick={handleFrameContinue}
                        style={{backgroundColor:"#c01219", padding: "10px 30px", fontSize:"18px", color:"#fff", borderRadius: "20px"}}/>

            </div>
            }

        </ToolContentWrapper>
    )
};

const ToolContentWrapper = styled.div`
    ${props => `
        ${props.backgroundColor && `background-color: ${props.backgroundColor}`};
        border-color: ${props.borderColor};
        border-width: ${props.borderSize}px;
        border-style: solid;
    `}
`;

const ToolContentItem = styled.div`
padding: 5px 0;
    max-height: ${props => props.maxHeight}px;
    overflow: auto;
`;