import React, {useContext} from "react";
import styled from "styled-components";
import classNames from "classnames";
import {TriviaContext} from "./tool-trivia";
import {MdDone, MdClose} from "react-icons/md";

export const TriviaIndicators = ({items, onClick, showFeedback}) => {
    const {userAnswers, activeSection, finalScreenActive} = useContext(TriviaContext);
    return (
        <div className="trivia-indicators">
            {items.map((item, index) => {
                const active = index === activeSection;
                const {u, a} = userAnswers[index];
                const isCorrect = u === a;
                const className = classNames("trivia-indicator", {active, correct: isCorrect && finalScreenActive});
                const answerValue = u >= 0 ? u + 1 : "";
                const showIcon = finalScreenActive || (showFeedback && isCorrect);
                const Icon = isCorrect ? MdDone : MdClose;

                return (
                    <IndicatorStyle className={className}
                                    onClick={() => onClick(index)}
                                    active={active}
                                    background={item.color}
                                    key={index}>
                        {showIcon ? <Icon color="#fff"/> : answerValue}
                    </IndicatorStyle>
                )
            })}
        </div>
    )
};

const IndicatorStyle = styled.div`
    &.trivia-indicator {
        background-color: ${props => props.background};
        ${props => props.active && 'border-color: #333'};
    }    
`;
