import React, { useReducer, useState, useEffect, useContext } from 'react';
import initialData from './helpers/puzzle-level-data';
import helpData from './helpers/puzzle-help-data';
import {MdHelpOutline } from 'react-icons/md';
import { HBox, Element } from 'react-stylesheet';
import {RiddleContext} from "../../05_screens/riddle/screen-riddle";

const MOVE_SQUARE = 'MOVE_SQUARE'; 
const HELP_ME = 'HELP_ME';

const windowWidth = window.innerWidth;
const boardSize = windowWidth > 370 ? 340 : window.innerWidth - 30;

const ActionsBtns = ({ btns, color }) => {
    return <HBox justifyContent={'center'} padding={'20px 0'}>
        {btns.map(btn => {
            const Icon = btn.icon;
            return (
                <Element
                    key={btn.id}
                    onClick={btn.action}
                    background={'transparent'}
                    // border={'2px solid #000'}
                    margin={'0 10px'}>
                    <Icon size={30} color={color || '#000'} />
                </Element>
            )
        }
        )}
    </HBox>
}

const getInitialData = (level, multiplier) => {
    return initialData(level, multiplier, boardSize);
};

const getHelpData = (level, multiplier) => {
    return helpData(level, multiplier, boardSize);
};

const getGameData = (level) => {
    let length, steps;
    switch (level) {
        case 1: length = 9; steps = 3; break;
        case 2: length = 16; steps = 4; break;
        case 3: length = 25; steps = 5; break;
        case 4: length = 36; steps = 6; break;
        case 5: length = 49; steps = 7; break;
        case 6: length = 64; steps = 8; break;
        case 7: length = 81; steps = 9; break;
        case 8: length = 100; steps = 10; break;
        default: length = 9; steps = 3; break;
    }
    return {
        squaresLength: length, steps: steps
    }
};

const squaresReducer = (state, action) => {
    if (action.type === MOVE_SQUARE) {
        const square = state.squaresData.find(sq => sq.location === action.location);
        let { top, left, location } = square;
        const movment = boardSize * (100 / action.steps * 0.01);
        switch (action.dir) {
            case 'up': { top -= movment; location -= action.steps; break; }
            case 'down': { top += movment; location += action.steps; break; }
            case 'left': { left -= movment; location -= 1; break; }
            case 'right': { left += movment; location += 1; break; }
            default: return;
        }

        state.squaresData.find(sq => sq.location === action.location).top = top;
        state.squaresData.find(sq => sq.location === action.location).left = left;
        state.squaresData.find(sq => sq.location === action.location).location = location;

        return {
            squaresData: state.squaresData
        }
    }
    if (action.type === HELP_ME) {
        // for (let i = 0; i < action.squares.length; i++) {
        //     const square = state.squaresData[i];
        // }
        return {
            squaresData: action.squaresData
        }
    }

    return state;
};



const PuzzleBoard = ({ level = 1, imageUrl, choosenCover, updateScore, showNumbers, finishGame, restart }) => {
    // const [isLoading, setIsLoading] = useState(true);
    const levelFinished = useContext(RiddleContext).updateGameLevel;

    const [warning, setWarning] = useState(null);
    const gameData = getGameData(level)
    const squaresLength = gameData.squaresLength - 1;
    const verticalSteps = gameData.steps;
    const [squares, setSqaures] = useReducer(squaresReducer, { squaresData: getInitialData(level, 100 / verticalSteps, gameData.order) });
    const [freeSquare, setFreeSquare] = useState(squaresLength);

    useEffect(() => {
        if (!squares.squaresData.some(square => square.location !== square.index)) {
            levelFinished();
            finishGame();
        }
    }, [squares]);

    const getDirection = (index, freeSquare) => {
        const
            up = index - verticalSteps,
            down = index + verticalSteps,
            right = index + 1,
            left = index - 1;

        if (up === freeSquare) { return 'up' }
        if (down === freeSquare) { return 'down' }
        if (left === freeSquare) { return 'left' }
        if (right === freeSquare) { return 'right' }
    };

    const handleSquareClick = location => {
        setWarning(null);
        const dir = getDirection(location, freeSquare);
        if (dir) {
            setSqaures({ type: MOVE_SQUARE, location: location, dir: dir, steps: verticalSteps });
            setFreeSquare(location);
            updateScore();
        } else {
            setWarning(location);
            setTimeout(() => setWarning(null), 1000)
        }
    };

    const renderSquares = () => {
        return (
            <div style={{ ...styles.board }}>
                {squares.squaresData.map(square => {
                    const isFreeSpace = freeSquare === square.location;
                    return (
                        <div key={square.index}
                            onClick={() => handleSquareClick(square.location)}
                            style={{
                                ...styles.square,
                                ...styles[`level_${level}`],
                                top: square.top + 'px',
                                left: square.left + 'px',
                                backgroundImage: !isFreeSpace && `url(${imageUrl})`,
                                backgroundSize: boardSize + 'px',
                                backgroundPosition: square.backgroundPosition,
                                // ...gameStatus === 'finish' && styles.gameFinishSquare,
                                ...warning === square.location && styles.warning
                            }}>
                            {showNumbers && <div style={styles.helpNumber}>{square.index + 1}</div>}
                        </div>

                    )
                })}
            </div>
        )
    };

    const helpMe = () => {
        setSqaures({ type: HELP_ME, squaresData: getHelpData(level, 100 / verticalSteps, gameData.order) });
        setFreeSquare(squares.squaresData.length);
        updateScore();
    }
    const actionsBtns = [
        // { id: 1, label: 'restart', icon: MdSettingsBackupRestore, action: restart },
        { id: 1, label: 'help', icon: MdHelpOutline, action: helpMe },
    ];
    return (
        <div>
            <div style={{ ...styles.cover, backgroundImage: `url(${choosenCover}` }}>
                <div style={styles.container}>
                    <div style={{ ...styles.boardWrapper }}>
                        <div style={{ ...styles.board }}>
                            {renderSquares()}
                        </div>
                    </div>
                </div>
            </div>
            <ActionsBtns btns={actionsBtns} color={"#fff"} />
        </div>
    )
};

const styles = {
    cover: {
        paddingBottom: '15px',
        paddingTop: '15px',
        backgroundSize: 'cover',
        position: 'relative',
        maxWidth: '400px',
        margin: '0 auto'
    },
    container: {
        width: boardSize + 'px',
        // height: boardSize + 'px',
        margin: '0 auto',
    },
    boardWrapper: {
        height: boardSize,
    },
    board: {
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(255, 255, 255, .2)'
    },
    square: {
        // border: '1px solid blue',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all .4s',
        // border: '2px solid #ffffff'
    },
    level_1: {
        width: '33.333%',
        height: '33.333%'
    },
    level_2: {
        width: '25%',
        height: '25%'
    },
    level_3: {
        width: '20%',
        height: '20%'
    },
    level_4: {
        width: '16.6668%',
        height: '16.6668%'
    },
    level_5: {
        width: '14.285%',
        height: '14.285%'
    },
    level_6: {
        width: '12.5%',
        height: '12.5%'
    },
    level_7: {
        width: '11.111%',
        height: '11.111%'
    },
    level_8: {
        width: '10%',
        height: '10%'
    },
    warning: {
        opacity: '.5'
    },
    gameFinishSquare: {
        border: 0
    },
    score: {
        marginBottom: '10px'
    },
    helpNumber: {
        width: 20,
        height: 20,
        // borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        fontSize: '12px',
        backgroundColor: 'rgba(255,255,255,.8)'
    }
};

export default PuzzleBoard;